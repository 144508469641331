<!-- <svelte:options customElement="clever-search-answer-filter" /> -->

<script lang="ts">
    import { onMount } from "svelte";
    import { shadowDomUpdates } from "../helpers/shadowdom-updates";
    import { state_indicesList } from "../store/state";
    import { ConfigStore } from "../store/config";

    import IndicesFilterComponent from "./answer-filter-indices.svelte";

    // onMount(() => {
    //     shadowDomUpdates("clever-search-answer-filter", true);
    // });
</script>

<div class="cs-search-result-answer-filter">
    {#if !!$state_indicesList.length}
        <IndicesFilterComponent />
    {/if}
</div>

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if} -->

<style lang="scss">
    @import "./../../scss/components/_answer-filter";
</style>
