<!--   components is used to toggle the 'clever-search'  -->
<svelte:options customElement="clever-search-toggler" />

<script lang="ts">
  import { fade, fly } from "svelte/transition";
  import { TogglePositionTypes } from "../enums/toggle-position-types.enum";
  import { WidgetType } from "../enums/widget-type.enum";
  import { ConfigStore } from "../store/config";
  /** store */
  import {
    state_showSearch,
    state_showBadge,
    state_sessionStorage,
  } from "../store/state";
  import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
  import { shadowDomUpdates } from "../helpers/shadowdom-updates";
  import { onMount } from "svelte";
  import ToggleGreeting from "./toggle-greeting.svelte";
  import { SessionStoreHandler } from "../helpers/session-storage-handler";
  import { elasticInOut } from "svelte/easing";

  $: getCssClass($ConfigStore.togglePosition);

  let wrapperClasses;

  let checkGreetingOnFirstToggle: boolean;
  let showGreetingPopup: boolean = true;
  let isGreetingInstant: boolean = false;

  onMount(() => {
    shadowDomUpdates("clever-search-toggler", true);

    if (SessionStoreHandler.get()?.visible) {
      //state_showSearch.set(true);
      window.dispatchEvent(
        new CustomEvent(WindowsCustomEventTypes.toggleView, { detail: true }),
      );
    } else
    /*if (state_showSearch)*/ greetingOnFirstToggleCheck();
  });

  window.addEventListener(WindowsCustomEventTypes.toggleView, (e) => {
    greetingOnFirstToggleCheck();
  });

  window.addEventListener(WindowsCustomEventTypes.showGreetingPopup, (e) => {
    isGreetingInstant = true;
    showGreetingPopup = true;
  });

  /** method to show/hide search panel*/
  const toggleView = () => {
    window.dispatchEvent(
      new CustomEvent(WindowsCustomEventTypes.toggleView, { detail: !$state_showSearch }),
    );
    //state_showSearch.set(!$state_showSearch);
    /** only on chatbot type view, the greeting message is loading when the chat interface is toggle for the first time. */
    //greetingOnFirstToggleCheck();
  };

  const greetingOnFirstToggleCheck = (): void => {
    if (!$state_showSearch) return;

    showGreetingPopup = false;

    if (checkGreetingOnFirstToggle) return;

    checkGreetingOnFirstToggle = true;

    //if ($ConfigStore.noGreeting) return;

    window.dispatchEvent(
      new CustomEvent(WindowsCustomEventTypes.requestGreeting),
    );
  };

  const getCssClass = (position: TogglePositionTypes): void => {
    let css = "toggler-wrapper";

    switch (position) {
      case TogglePositionTypes.leftBottom:
        css += " cs-flt-lb";
        break;
      case TogglePositionTypes.leftTop:
        css += " cs-flt-lt";
        break;
      case TogglePositionTypes.rightBottom:
        css += " cs-flt-rb";
        break;
      case TogglePositionTypes.rightTop:
        css += " cs-flt-rt";
        break;
      default:
        break;
    }

    setTimeout(() => {
      wrapperClasses = css;
    }, $ConfigStore.toggleInitShowDelay);
  };
</script>

{#if !!$ConfigStore.apiBaseUrl && $state_showBadge}
  {#if $ConfigStore.widgetType === WidgetType.cb && !$state_showSearch && !!wrapperClasses}
    <div
      class={wrapperClasses}
      in:fly|global={{ y: 80, duration: 2000, easing: elasticInOut }}
      out:fade|global
      style:bottom={$ConfigStore.customTheme.launchBottomPos}
      style:right={$ConfigStore.customTheme.launchRightPos}
    >
      {#if $ConfigStore.toggleSrcHtml?.length > 0}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div on:click={toggleView}>{@html $ConfigStore.toggleSrcHtml}</div>
      {:else if $ConfigStore.toggleSrcImg?.length > 0}
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <img
          class="cs-tgl-img"
          on:click={toggleView}
          src={$ConfigStore.toggleSrcImg}
          alt="Show Chat"
        />
      {:else}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="974"
          height="803"
          class="cs-tgl-default"
          viewBox="0 0 974 803"
          on:click={toggleView}
          on:keydown={toggleView}
          role="button"
        >
          <defs>
            <filter
              id="filter"
              x="21"
              y="22.969"
              width="487"
              height="650.343"
              filterUnits="userSpaceOnUse"
            >
              <feFlood result="flood" flood-color="#555" />
              <feComposite
                result="composite"
                operator="in"
                in2="SourceGraphic"
              />
              <feBlend result="blend" in2="SourceGraphic" />
            </filter>
            <filter
              id="filter-2"
              x="156.875"
              y="29.344"
              width="795.406"
              height="745.656"
              filterUnits="userSpaceOnUse"
            >
              <feFlood result="flood" flood-color="#2696c1" />
              <feComposite
                result="composite"
                operator="in"
                in2="SourceGraphic"
              />
              <feBlend result="blend" in2="SourceGraphic" />
            </filter>
            <filter
              id="filter-3"
              x="332"
              y="318"
              width="101"
              height="101"
              filterUnits="userSpaceOnUse"
            >
              <feFlood result="flood" flood-color="#fff" />
              <feComposite
                result="composite"
                operator="in"
                in2="SourceGraphic"
              />
              <feBlend result="blend" in2="SourceGraphic" />
            </filter>
            <filter
              id="filter-4"
              x="496"
              y="318"
              width="101"
              height="101"
              filterUnits="userSpaceOnUse"
            >
              <feFlood result="flood" flood-color="#fff" />
              <feComposite
                result="composite"
                operator="in"
                in2="SourceGraphic"
              />
              <feBlend result="blend" in2="SourceGraphic" />
            </filter>
            <filter
              id="filter-5"
              x="659"
              y="318"
              width="101"
              height="101"
              filterUnits="userSpaceOnUse"
            >
              <feFlood result="flood" flood-color="#fff" />
              <feComposite
                result="composite"
                operator="in"
                in2="SourceGraphic"
              />
              <feBlend result="blend" in2="SourceGraphic" />
            </filter>
          </defs>
          <g id="Artboard_1" data-name="Artboard 1">
            <g style="fill: #e30000; filter: url(#filter)">
              <path
                id="path-2"
                style:fill={$ConfigStore.customTheme.badgeIconColor}
                class="cls-1"
                d="M508,638l-79,9L210,671S32.511,705.581,21,508V181S24.261-10.169,241,28L507,78l-2,7Z"
                style="stroke: inherit; filter: none; fill: inherit"
              />
            </g>
            <use
              xlink:href="#path-2"
              style="stroke: #000; filter: none; fill: none"
            />
            <g style="fill: #e30000; filter: url(#filter-2)">
              <path
                id="path"
                style:fill={$ConfigStore.customTheme.badgeBg}
                class="cls-2"
                d="M547,775l36-107s6.918-9.187-11-11-252-28-252-28-162.755-8.812-163-159,0-191,0-191-7.467-135,152-164S761,31,761,31,951.341,0,952,206s0,322,0,322S928.3,696,769,678l-24-2s-16.691-7-38,6S547,775,547,775Z"
                style="stroke: inherit; filter: none; fill: inherit"
              />
            </g>
            <use
              xlink:href="#path"
              style="stroke: #000; filter: none; fill: none"
            />
            <g style="fill: #e30000; filter: url(#filter-3)">
              <circle
                id="circle-3"
                class="cls-3"
                cx="382.5"
                cy="368.5"
                r="50.5"
                style="stroke: inherit; filter: none; fill: inherit"
              />
            </g>
            <use
              xlink:href="#circle-3"
              style="stroke: #000; filter: none; fill: none"
            />
            <g style="fill: #e30000; filter: url(#filter-4)">
              <circle
                id="circle-2"
                class="cls-4"
                cx="546.5"
                cy="368.5"
                r="50.5"
                style="stroke: inherit; filter: none; fill: inherit"
              />
            </g>
            <use
              xlink:href="#circle-2"
              style="stroke: #000; filter: none; fill: none"
            />
            <g style="fill: #e30000; filter: url(#filter-5)">
              <circle
                id="circle"
                class="cls-5"
                cx="709.5"
                cy="368.5"
                r="50.5"
                style="stroke: inherit; filter: none; fill: inherit"
              />
            </g>
            <use
              xlink:href="#circle"
              style="stroke: #000; filter: none; fill: none"
            />
          </g>
        </svg>
      {/if}

      {#if showGreetingPopup}
        <ToggleGreeting instant={isGreetingInstant}></ToggleGreeting>
      {/if}
    </div>
  {/if}
{/if}

{#if $ConfigStore.customCSSUrl}
  <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}

<style lang="scss">
  @import "./../../scss/components/_toggler";
</style>
