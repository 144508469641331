<!-- <svelte:options customElement="clever-search-result-content" /> -->

<script lang="ts">
    import { ConfigStore } from "../store/config";
    import { state_searchResult } from "../store/state";

    import { fade } from "svelte/transition";
    import type { IAnswer } from "../interfaces/answer.interface";
    import { state_answerRating } from "../store/state";

    import { _ } from "svelte-i18n";
    import AnswerSourceComponent from "./answer-source.svelte";
    import RatingExtendComponent from "./rating-extend.svelte";
    import RatingComponent from "./rating.svelte";
    import SocialSharingComponent from "./social-sharing.svelte";

    let showRating: boolean = false;
    let ratingTimer: any;
    let currentAnswer;

    const bulletItems = ["•", "-"];

    $: isRatingVisible($state_searchResult);

    $: listOfAnswerItems = $state_searchResult.answer.split("\n");

    // onMount(() => {
    //     shadowDomUpdates("clever-search-result-content", true);
    // });

    const isRatingVisible = (x: IAnswer) => {
        if (currentAnswer === x.answer) return;

        state_answerRating.set(null);
        currentAnswer = x.answer;

        clearTimeout(ratingTimer);
        setTimeout(() => {
            showRating = true;
        }, $ConfigStore.ratingDelay);
    };

    const isBulletPoint = (x): boolean => {
        return new RegExp(bulletItems.join("|")).test(x);
    };
</script>

<div transition:fade|global>
    <div class="cs-search-result-wrapper">
        <div class="cs-search-result-answer">
            <div class="cs-search-result-says">
                {$ConfigStore.agentName}
                {$_("result_content.says")}:
            </div>

            {#if listOfAnswerItems?.length > 1}
                <ul class="cs-search-result-list-list">
                    {#each listOfAnswerItems as item}
                        {#if item.trim()}
                            <li class:bulletItem={isBulletPoint(item)}>
                                {item}
                            </li>
                        {/if}
                    {/each}
                </ul>
            {:else}
                {@html $state_searchResult.answer}
            {/if}
        </div>
        {#if $state_searchResult.answer}
            {#if showRating}
                <div class="cs-result-rating-wrapper">
                    {#if $ConfigStore.mediaSharing}
                        <div class="social-share">
                            <SocialSharingComponent />
                        </div>
                    {/if}
                    {#if showRating}
                        <div>
                            <div class="cs-result-rating-default">
                                <RatingComponent />
                            </div>
                            <div>
                                <RatingExtendComponent />
                            </div>
                        </div>
                    {/if}
                </div>
            {/if}
        {/if}
        {#if !!$state_searchResult?.document_data?.length}
            <AnswerSourceComponent />
        {/if}
    </div>
</div>

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if} -->

<style lang="scss">
    @import "./../../scss/components/_result-content";
</style>
