
import type { IRequestSearchFaq } from "../../interfaces/request-search-faq.interface";
import type { IRequestSearchSuggestion } from "../../interfaces/request-search-suggestion.interface";
import { state_faq } from "../../store/state";
import { Logger } from "../../telemetry/logger";
import { GetSuggestions } from "../api/get-suggestions";

export class FaqHelper {

    private logger = new Logger();;

    constructor() { }

    public async get(params: IRequestSearchSuggestion | IRequestSearchFaq) {

        try {

            const res = await GetSuggestions(params, null);

            if (res?.ok) {
                const searchSuggestion = await res.json();
                state_faq.set(searchSuggestion.hits);
            }

        } catch (e) {

            this.logger.error('FaqHelper', e)
        }
    }

}