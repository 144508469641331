<script lang="ts">
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import { ConfigStore } from "../store/config";
    import { state_showSearch } from "../store/state";

    $: logoSrc = getLogoImg;

    const getLogoImg = (): string => {
        if ($ConfigStore.agentImg) return $ConfigStore.agentImg;

        if ($ConfigStore.logoMini?.length) {
            if ($ConfigStore.logoMini?.substring(0, 4) == "http")
                return $ConfigStore.logoMini;

            return (
                ($ConfigStore.assetBaseUrl || $ConfigStore.apiBaseUrl) +
                $ConfigStore.logoMini
            );
        }

        if ($ConfigStore.logoMain?.length) {
            if ($ConfigStore.logoMain?.substring(0, 4) == "http")
                return $ConfigStore.logoMain;

            return (
                ($ConfigStore.assetBaseUrl || $ConfigStore.apiBaseUrl) +
                $ConfigStore.logoMain
            );
        }

        return "";
    };
</script>

<div
    class="cs-cb-header"
    style:background={$ConfigStore.customTheme.headerBg}
    style:color={$ConfigStore.customTheme.headerTextColor}
>
    {#if logoSrc()?.length}
        <div class="cs-cb-img">
            <img src={logoSrc()} alt={$ConfigStore.productName} />
        </div>
    {:else}
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="974"
            height="803"
            class="cs-cb-default-img"
            viewBox="0 0 974 803"
        >
            <defs>
                <filter
                    id="filter"
                    x="21"
                    y="22.969"
                    width="487"
                    height="650.343"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood result="flood" flood-color="#555" />
                    <feComposite
                        result="composite"
                        operator="in"
                        in2="SourceGraphic"
                    />
                    <feBlend result="blend" in2="SourceGraphic" />
                </filter>
                <filter
                    id="filter-2"
                    x="156.875"
                    y="29.344"
                    width="795.406"
                    height="745.656"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood result="flood" flood-color="#2696c1" />
                    <feComposite
                        result="composite"
                        operator="in"
                        in2="SourceGraphic"
                    />
                    <feBlend result="blend" in2="SourceGraphic" />
                </filter>
                <filter
                    id="filter-3"
                    x="332"
                    y="318"
                    width="101"
                    height="101"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood result="flood" flood-color="#fff" />
                    <feComposite
                        result="composite"
                        operator="in"
                        in2="SourceGraphic"
                    />
                    <feBlend result="blend" in2="SourceGraphic" />
                </filter>
                <filter
                    id="filter-4"
                    x="496"
                    y="318"
                    width="101"
                    height="101"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood result="flood" flood-color="#fff" />
                    <feComposite
                        result="composite"
                        operator="in"
                        in2="SourceGraphic"
                    />
                    <feBlend result="blend" in2="SourceGraphic" />
                </filter>
                <filter
                    id="filter-5"
                    x="659"
                    y="318"
                    width="101"
                    height="101"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood result="flood" flood-color="#fff" />
                    <feComposite
                        result="composite"
                        operator="in"
                        in2="SourceGraphic"
                    />
                    <feBlend result="blend" in2="SourceGraphic" />
                </filter>
            </defs>
            <g id="Artboard_1" data-name="Artboard 1">
                <g style="fill: #e30000; filter: url(#filter)">
                    <path
                        id="path-2"
                        style:fill={$ConfigStore.customTheme.badgeIconColor}
                        class="cls-1"
                        d="M508,638l-79,9L210,671S32.511,705.581,21,508V181S24.261-10.169,241,28L507,78l-2,7Z"
                        style="stroke: inherit; filter: none; fill: inherit"
                    />
                </g>
                <use
                    xlink:href="#path-2"
                    style="stroke: #000; filter: none; fill: none"
                />
                <g style="fill: #e30000; filter: url(#filter-2)">
                    <path
                        id="path"
                        style:fill={$ConfigStore.customTheme.badgeBg}
                        class="cls-2"
                        d="M547,775l36-107s6.918-9.187-11-11-252-28-252-28-162.755-8.812-163-159,0-191,0-191-7.467-135,152-164S761,31,761,31,951.341,0,952,206s0,322,0,322S928.3,696,769,678l-24-2s-16.691-7-38,6S547,775,547,775Z"
                        style="stroke: inherit; filter: none; fill: inherit"
                    />
                </g>
                <use
                    xlink:href="#path"
                    style="stroke: #000; filter: none; fill: none"
                />
                <g style="fill: #e30000; filter: url(#filter-3)">
                    <circle
                        id="circle-3"
                        class="cls-3"
                        cx="382.5"
                        cy="368.5"
                        r="50.5"
                        style="stroke: inherit; filter: none; fill: inherit"
                    />
                </g>
                <use
                    xlink:href="#circle-3"
                    style="stroke: #000; filter: none; fill: none"
                />
                <g style="fill: #e30000; filter: url(#filter-4)">
                    <circle
                        id="circle-2"
                        class="cls-4"
                        cx="546.5"
                        cy="368.5"
                        r="50.5"
                        style="stroke: inherit; filter: none; fill: inherit"
                    />
                </g>
                <use
                    xlink:href="#circle-2"
                    style="stroke: #000; filter: none; fill: none"
                />
                <g style="fill: #e30000; filter: url(#filter-5)">
                    <circle
                        id="circle"
                        class="cls-5"
                        cx="709.5"
                        cy="368.5"
                        r="50.5"
                        style="stroke: inherit; filter: none; fill: inherit"
                    />
                </g>
                <use
                    xlink:href="#circle"
                    style="stroke: #000; filter: none; fill: none"
                />
            </g>
        </svg>
    {/if}

    <div class="cs-cb-info">
        {#if $ConfigStore.agentName?.length}
            <div class="cs-agent-name">{$ConfigStore.agentName}</div>
        {/if}
        {#if $ConfigStore.agentTagline?.length}
            <div class="cs-agent-headline">{$ConfigStore.agentTagline}</div>
        {/if}
    </div>
    {#if $ConfigStore.headerCustomHtml?.length > 0}
    <div class="cs-cb-external">
       {@html $ConfigStore.headerCustomHtml}
    </div>
    {/if}
    <div class="cs-cb-action">
        <button
            on:click={() => {
                //state_showSearch.set(false);
                window.dispatchEvent(
                    new CustomEvent(WindowsCustomEventTypes.toggleView, { detail: false }),
                );
            }}
            style:color={$ConfigStore.customTheme.headerTextColor}
        >
            <div class="icon-arrow-up cs-default-icon" />
        </button>
    </div>
</div>

{#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}

<style lang="scss">
    @import "./../../scss/components/chat-bot-header";
</style>
