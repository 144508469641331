<script lang="ts">
    import { draw, fade, fly, scale } from "svelte/transition";
    import {
        state_conversationResults,
        state_sessionStorage,
    } from "../store/state";
    import { onMount } from "svelte";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import { SessionStoreHandler } from "../helpers/session-storage-handler";
    import { ConfigStore } from "../store/config";
    import { elasticInOut, elasticOut, quintOut } from "svelte/easing";

    export let instant: boolean = false;

    let isVisible: boolean = null;

    window.addEventListener(WindowsCustomEventTypes.showGreetingPopup, (e) => {
        if ($state_conversationResults.length === 0) {
            isGreetingPopupRequest(true);
            isVisible = true;
        } else {
            isVisible = true;
        }
    });

    onMount(() => {
        if ($state_sessionStorage?.offGrtTs) {
            isExpired();
            return;
        }

        isGreetingPopupRequest(instant);
    });

    /** method to request greeting when popup greeting is enabled*/
    const isGreetingPopupRequest = (isInstant: boolean): void => {
        if ($ConfigStore.greetingOnToggle) {
            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.requestGreeting)
            );

            if (isInstant) {
                isVisible = true;
            } else {
                setTimeout(() => {
                    isVisible = true;
                }, $ConfigStore.greetingOnToggleDelay);
            }
        }
    };

    const toggleView = () => {
        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.toggleView, {}),
        );
    };

    const isExpired = () => {
        const offGrtTs = new Date($state_sessionStorage?.offGrtTs);
        const now = new Date();

        // check offGrtTs and now difference is more than set expiry time
        if (
            now.getTime() - offGrtTs.getTime() >
            $ConfigStore.greetingExpiryTime
        ) {
            // debugger;
            SessionStoreHandler.remove("offGrtTs");
            isVisible = true;
            isGreetingPopupRequest(instant);
        }
    };

    const hideGreeting = () => {
        isVisible = false;
        // create ddt which value should be 7hrs from now in toISOString.
        const ddt = new Date();
        // ddt.setHours(ddt.getHours() + 7);

        SessionStoreHandler.update({
            offGrtTs: ddt.toISOString(),
        });
    };
</script>

{#if $state_conversationResults.length === 1 && $state_conversationResults[0].is_greeting && isVisible}
    <div
        class="cs-tgl-grt-outer-wrapper"
        in:fly|global={{ y: 50, duration: 2000, easing: elasticInOut }}
        out:fade|global
    >
        <button on:click={hideGreeting} class="cl-icn-close icon-cross"
        ></button>
        <div
            class="cs-tgl-grt-wrapper"
            on:click={toggleView}
            on:keydown={toggleView}
            role="button"
            tabindex="0"
        >
            {@html $state_conversationResults[0].answer}
        </div>
    </div>
{/if}

<style lang="scss">
    @import "./../../scss/components/_toggler_greeting";
</style>
