<script lang="ts">
  import { fade } from "svelte/transition";
  import { WidgetType } from "../enums/widget-type.enum";
  import { ConfigStore } from "./../store/config";
  import { shadowDomUpdates } from "../helpers/shadowdom-updates";
  import { onMount } from "svelte";
</script>

{#if ![WidgetType.cb, WidgetType.chat].includes($ConfigStore.widgetType)}
  <div class="cs-search-loading" in:fade|global>
    <div />
    <div />
    <div />
    <div class="last" />
  </div>
<!-- {:else if $ConfigStore.streamMsg}
  <div class="cs-search-loading inside-bubble" in:fade|global>
    <div />
    <div class="last" />
  </div> -->
{:else}
  <div class="cs-search-loading-chat" transition:fade|global>
    <div id="dots">
      <span />
      <span />
      <span />
      <span />
    </div>
  </div>
{/if}
{#if $ConfigStore.customCSSUrl}
  <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}

<style lang="scss">
  @import "./../../scss/components/_busy";
</style>
