import { register, init, getLocaleFromNavigator } from 'svelte-i18n';

register('en', () => import('./locales/en.json'));
register('de', () => import('./locales/de.json'));
register('fi', () => import('./locales/fi.json'));
register('sv', () => import('./locales/sv.json'));
register('it', () => import('./locales/it.json'));
register('es', () => import('./locales/es.json'));
register('nl', () => import('./locales/nl.json'));
register('fr', () => import('./locales/fr.json'));
register('ja', () => import('./locales/ja.json'));
register('ta', () => import('./locales/ta-in.json'));
register('pt', () => import('./locales/pt.json'));
register('hi', () => import('./locales/hi.json'));
register('zh', () => import('./locales/zh-cn.json'));
register('zh-cn', () => import('./locales/zh-cn.json'));
register('zh-hk', () => import('./locales/zh-hk.json'));
register('zh-tw', () => import('./locales/zh-tw.json'));
register('zh-sg', () => import('./locales/zh-sg.json'));
register('zh-mo', () => import('./locales/zh-mo.json'));
register('zh-hant', () => import('./locales/zh-hant.json'));
register('zh-hans', () => import('./locales/zh-hans.json'));
register('ta-in', () => import('./locales/ta-in.json'));


let customLocale
if (window['gqaLocale']) {
    customLocale = window['gqaLocale'];
    delete window['gqaLocale'];
    customLocale = customLocale.toLowerCase()
} else {
    customLocale = getLocaleFromNavigator();
}

init({
    fallbackLocale: 'en',
    initialLocale: customLocale
});