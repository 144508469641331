import { get } from "svelte/store";
import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
import { WindowIntegrations } from "../helpers/window-integrations";
import { state_conversationResults, state_sessionStorage } from "../store/state";
import { Logger } from "../telemetry/logger";
import { AnswerHelper } from "./handlers/answer.handler";
import { ConfigHandler } from "./handlers/config.handler";
import { FaqHelper } from "./handlers/faq.handler";
import { IndicesHelper } from "./handlers/indices.handler";
import { RatingHelper } from "./handlers/rating.handler";
import { SuggestionHandler } from "./handlers/suggestion.handler";
import { SessionStoreHandler } from "../helpers/session-storage-handler";
import { ConversationHistoryHelper } from "../helpers/conversation-history-helper";
import { ThreadHandler } from "./handlers/thread.handler";
import { ConfigStore } from "../store/config";


export class SearchController {


    private logger = new Logger();
    private configHandler : ConfigHandler;
    private getSuggestionHandler : SuggestionHandler;
    private getAnswerHelper : AnswerHelper;
    private getFaqHelper : FaqHelper;
    private ratingHelper : RatingHelper;
    private indicesHelper : IndicesHelper;
    private threadHandler: ThreadHandler;

    constructor(

    ) {

        WindowIntegrations();

        this.configHandler = new ConfigHandler();
        this.getSuggestionHandler = new SuggestionHandler()
        this.getAnswerHelper = new AnswerHelper();
        this.getFaqHelper = new FaqHelper();
        this.ratingHelper = new RatingHelper();
        this.indicesHelper = new IndicesHelper()
        this.threadHandler = new ThreadHandler()

        this.listeners();
        this.initReady();
        this.initSessionStorage();
    }

    /** send window event after controller is ready */
    private initReady(): void {
        window.dispatchEvent(new CustomEvent(WindowsCustomEventTypes.ready))
    }

    private listeners(): void {
        const _this = this;

        /** check for type of action */
        function triggerAction(e): void {
            try {


                switch (e.type) {

                    case WindowsCustomEventTypes.requestSearch:
                        _this.getAnswerHelper.get(e.detail)
                        break;

                    case WindowsCustomEventTypes.updateRating:
                        _this.ratingHelper.update(e.detail)
                        break;

                    case WindowsCustomEventTypes.requestSuggestion:
                        _this.getSuggestionHandler.get(e.detail)
                        break;

                    case WindowsCustomEventTypes.requestIndices:
                        _this.indicesHelper.get();
                        break;

                    case WindowsCustomEventTypes.requestThread:
                        _this.threadHandler.get();
                        break;    

                    case WindowsCustomEventTypes.restartThread:
                        _this.threadHandler.clear();
                        _this.requestGreeting();
                        break;    

                    case WindowsCustomEventTypes.requestFAQ:
                        _this.getFaqHelper.get(e.detail);
                        break;

                    case WindowsCustomEventTypes.requestPostOption:
                        _this.configHandler.get(e?.detail)
                        break;

                    case WindowsCustomEventTypes.requestGreeting:
                        _this.requestGreeting();
                        break;    

                    case WindowsCustomEventTypes.toggleView:
                        _this.toggleInit();
                        break;
                    default:
                        break;
                }
            } catch (err) {
                _this.logger.warn('SearchController/triggerAction ', err)
            }
        }


        /** listen to custom events */
        [
            WindowsCustomEventTypes.requestSearch,
            WindowsCustomEventTypes.updateRating,
            WindowsCustomEventTypes.requestSuggestion,
            WindowsCustomEventTypes.requestIndices,
            WindowsCustomEventTypes.requestThread,
            WindowsCustomEventTypes.restartThread,
            WindowsCustomEventTypes.requestFAQ,
            WindowsCustomEventTypes.requestServerConfig,
            WindowsCustomEventTypes.requestServerInit,
            WindowsCustomEventTypes.requestPostOption,
            WindowsCustomEventTypes.requestGreeting,
        ].forEach(evt =>
            window.addEventListener(evt, triggerAction)
        );
    }

    private toggleInit(): void {
        if (ConversationHistoryHelper.isEmpty()) {
            this.indicesHelper.get();
        }
    }

    private initSessionStorage(): void {
        const current = SessionStoreHandler.get();
        state_sessionStorage.set(current);
    }

    public async requestGreeting(): Promise<void> {
        if (get(ConfigStore).loadThreadHistory) {
            await this.threadHandler.get();
        }
        
        if (!get(ConfigStore).noGreeting) {
            await this.getAnswerHelper.get({
                    query: null,
                    isGreetingRequest: true,
                    attributes: get(ConfigStore).customAttributes?.attributes,
                    cached_query: get(ConfigStore).customAttributes?.cachedQuery
            });
        }

    }
}