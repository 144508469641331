<svelte:options customElement="clever-search-cb" />

<script lang="ts">
    /** stores */
    import { ConfigStore } from "./lib/store/config";
    import { state_showSearch } from "./lib/store/state";
    /** others */
    import { fly } from "svelte/transition";
    import { WidgetType } from "./lib/enums/widget-type.enum";
    import { shadowDomUpdates } from "./lib/helpers/shadowdom-updates";
    import { onMount } from "svelte";

    import ChatBotHeader from "./lib/components/chat-bot-header.svelte";

    $ConfigStore = { ...$ConfigStore, widgetType: WidgetType.cb };

    onMount(() => {
        shadowDomUpdates("clever-search-cb", true);
    });
</script>

{#if !!$ConfigStore.apiBaseUrl}
    {#if $state_showSearch}
        <div
            class="cs-wrapper cs-chatbot"
            transition:fly|global={{ y: 50, duration: 1000 }}
            style:background={$ConfigStore.customTheme.bodyBg}
            style:border-color={$ConfigStore.customTheme.bodyBorderColor}
        >
            <ChatBotHeader />
            <clever-search-chat />
        </div>
    {/if}
{/if}

{#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}

<style lang="scss">
    @import "./scss/pages/chat-bot";
</style>
