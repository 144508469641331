<!-- <svelte:options customElement="clever-search-rating" /> -->

<script lang="ts">
    import {
        state_answerRating,
        state_busyApi,
        state_conversationResults,
        state_searchResult,
    } from "../store/state";
    import { ConfigStore } from "./../store/config";

    import { onDestroy } from "svelte";
    import { _ } from "svelte-i18n";
    import { ApiTypes } from "../enums/api.enum";
    import { RatingTypes } from "../enums/question-rating.enum";
    import { WidgetType } from "../enums/widget-type.enum";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import type { IUpdateRating } from "./../interfaces/request-update-rating-question.interface";

    let updateTimer: any;
    let updateSuccess: boolean;
    let updateFail: boolean;

    /** property to store timeout period for showing status*/
    let clearTime = 5000;

    $: isUpdating = $state_busyApi.includes(ApiTypes.putRating);

    // onMount(() => {
    //     shadowDomUpdates("clever-search-rating", true);
    // });

    onDestroy(() => {
        window.removeEventListener(
            WindowsCustomEventTypes.UpdateRatingStatus,
            showUpdateStatus
        );
    });

    const sendRating = (val: any) => {
        if ($state_answerRating == val) return;

        updateSuccess = false;
        updateFail = false;
        clearTimeout(updateTimer);

        const data: IUpdateRating = {
            score: val,
            type: RatingTypes.thumbs,
            questionId: [WidgetType.chat, WidgetType.cb].includes(
                $ConfigStore.widgetType
            )
                ? $state_conversationResults[
                      $state_conversationResults.length - 1
                  ].question_id
                : $state_searchResult.question_id,
        };

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.updateRating, {
                detail: data,
            })
        );
    };

    const showUpdateStatus = (e) => {
        try {
            const status = e.detail;

            updateSuccess = false;
            updateFail = false;
            clearTimeout(updateTimer);

            if (status) {
                updateSuccess = true;
            } else {
                updateFail = true;
            }

            updateTimer = setTimeout(() => {
                updateSuccess = false;
                updateFail = false;
            }, clearTime);
        } catch (e) {
            console.error(e);
        }
    };

    /** listen to window even for rating api update status*/
    window.addEventListener(
        WindowsCustomEventTypes.UpdateRatingStatus,
        showUpdateStatus
    );
</script>

{#if $ConfigStore.ratingType === RatingTypes.nps}
    todo
{:else}
    <!-- default loading is thumbs view-->
    <div class={"cs-rating-wrapper cs-type-" + $ConfigStore.widgetType}>
        <div class="cs-rating-wrapper-panel">
            {#if updateSuccess}
                <div class="cs-rating-success">
                    {$_("rating.thank_feedback")}
                </div>
            {/if}
            {#if updateFail}
                <div class="cs-rating-error">
                    {$_("rating.unable_send_feedback")}
                </div>
            {/if}
            <div class="cs-info">{$_("rating.how_did_i_do")}</div>
            <div
                class="cs-thumb up"
                class:busy={isUpdating}
                class:active={$state_answerRating?.score == 1}
                on:keypress={() => sendRating(1)}
                on:click={() => sendRating(1)}
            />
            <div
                class="cs-thumb down busy"
                class:busy={isUpdating}
                class:active={$state_answerRating?.score == 0}
                on:keypress={() => sendRating(0)}
                on:click={() => sendRating(0)}
            />
        </div>
    </div>
{/if}
{#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}

<style lang="scss">
    @import "./../../scss/components/_rating";
</style>
