import { get } from "svelte/store";
import type { ApiTypes } from "../enums/api.enum";
import type { IApiError } from "../interfaces/api-error.interface";
import { state_apiErrors, state_busyApi } from "../store/state";
import { Logger } from "../telemetry/logger";

const logger = new Logger();;

export function addApiError(err: IApiError): void {

    state_apiErrors.set([...get(state_apiErrors), err])
    logger.error(`${err.apiType} + - API`, JSON.stringify(err))
}

export function removeApiError(type: ApiTypes): void {
    state_apiErrors.set([...get(state_apiErrors).filter(x => x.apiType !== type)])
}

export function addBusyApi(type: ApiTypes): void {
    state_busyApi.set([...get(state_busyApi), type])
}

export function removeBusyApi(type: ApiTypes): void {
    state_busyApi.set([...get(state_busyApi).filter(x => x !== type)])
}