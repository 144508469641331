import { get } from "svelte/store";
import { WidgetType } from "../enums/widget-type.enum";
import { ConfigStore } from "../store/config";

/** method to append the components to the html
 * 
 * tags: 
 *  name: component tag,
 *  parent: parent place holder. this could be id, class, tag
 */
export function appendComponents(): void {

    let tags = []

    switch (get(ConfigStore).widgetType) {
        case WidgetType.chat:
            tags = ['clever-search-chat']
            break;
        case WidgetType.cb:
            tags = ['clever-search-cb', 'clever-search-toggler']
            break;
        default:
            tags = ['clever-search-fp']
            break;
    }

    var getPlaceHolder = function (x) {
        try {
            if (x.length)
                return document.querySelector(x)
            else
                return document.body;
        } catch {
            return document.body;
        }
    }

    for (let item of tags) {
        var el = getPlaceHolder(get(ConfigStore).widgetParentEle);
        var tag = document.createElement(item); // is a node
        el.appendChild(tag);
    }

}