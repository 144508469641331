<!-- <svelte:options customElement="clever-search-source-related-search" /> -->

<script lang="ts">
    import { _ } from "svelte-i18n";
    import { WidgetType } from "../enums/widget-type.enum";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import type { IRequestSearch } from "../interfaces/request-search-question.interface";
    import { ConfigStore } from "../store/config";

    import {
        state_conversationResults,
        state_searchResult,
        state_selectedIndicesList,
        state_sourceRelatedSearchSuggestions,
    } from "../store/state";
    $: list = $state_sourceRelatedSearchSuggestions?.data
        ? $state_sourceRelatedSearchSuggestions?.data
              .split("\n")
              .sort(() => 0.5 - Math.random())
        : [];

    $: currentQuestion = [WidgetType.chat, WidgetType.cb].includes(
        $ConfigStore.widgetType
    )
        ? $state_conversationResults[$state_conversationResults.length - 1]
              ?.question
        : $state_searchResult.question;

    // onMount(() => {
    //     shadowDomUpdates("clever-search-source-related-search", true);
    // });

    const searchNow = (x: string): void => {
        const data: IRequestSearch = {
            query: x.replace(new RegExp(/•|-/gm, "g"), "").trim(),
            document_id: $state_sourceRelatedSearchSuggestions.document_id,
            index: $state_sourceRelatedSearchSuggestions?.index,
        };

        if (
            $state_selectedIndicesList.length &&
            [WidgetType.chat, WidgetType.cb].includes($ConfigStore.widgetType)
        )
            data.index = $state_selectedIndicesList
                .toString()
                .replace(", ", "");

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                detail: data,
            })
        );
    };
</script>

{#if list}
    <div class={"cs-related-search cs-type-" + $ConfigStore.widgetType}>
        <div class="cs-related-search-title">
            {$_("source_related_search.related_searches_from_source")}
        </div>
        <div class="cs-related-search-item">
            {#each list.slice(0, 5) as item}
                {#if item
                    .replace(new RegExp(/•|-/gm, "g"), "")
                    .trim() && item.replace(new RegExp(/•|-/gm, "g"), "") !== currentQuestion}
                    <button on:click={() => searchNow(item)}
                        >{item
                            .replace(new RegExp(/•|-/gm, "g"), "")
                            .trim()}</button
                    >
                {/if}
            {/each}
        </div>
    </div>
{/if}

<style lang="scss">
    @import "./../../scss/components/_related-search";
</style>
