import markdownit from 'markdown-it';

export class MarkupHelper {
    private md;

    constructor(
    ) {
        this.md = markdownit({ html: true, linkify: true, typographer: true });
    }

    public render(input: string): string {
        if (input) {
            var cleanedInput = input
            .replace(/<(thinking|search_quality_reflection|search_quality_score)>[\s\S]*?<\/\1>/g, '')
            .replace(/<(thinking|search_quality_reflection|search_quality_score)>[\s\S]*/g, '');
            return this.md.render(cleanedInput);
        } else {
            return "";
        }
    }
}