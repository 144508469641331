
import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { WidgetType } from "../../enums/widget-type.enum";
import { WindowsCustomEventTypes } from "../../enums/windows-custom-event.enum";
import { addBusyApi, removeBusyApi } from "../../helpers/handle-api";
import type { IAnswer } from "../../interfaces/answer.interface";
import type { IRequestSearch } from "../../interfaces/request-search-question.interface";
import type { IStreamQuestion } from "../../interfaces/stream-question.interface";
import { ConfigStore } from "../../store/config";
import { state_answerRating, state_busyApi, state_conversationResults, state_searchResult, state_searchString, state_searchSuggestion, state_showSearch, state_updatedAttributes } from "../../store/state";
import { Logger } from "../../telemetry/logger";
import { postQuestion } from "../api/post-question";
import { postQuestionStream } from "../api/post-question-stream";
import { AlternativeHelper } from "./alternative.handler";
import { QuestionRelatedSuggestionsHandler } from "./question-related-suggestions.handler";
import { SourceRelatedSuggestionsHandler } from "./source-related-suggestions.handler";
import { ConversationHistoryHelper } from "../../helpers/conversation-history-helper";
import { CitationHelper } from "../../helpers/citation-helper";
import type { IToolResponse } from "src/lib/interfaces/tool-response.interface";
import type { IToolCall } from "src/lib/interfaces/tool-call.interface";
import { MarkupHelper } from "../../helpers/markup-helper";

export class AnswerHelper {


    private questionRelatedSuggestionsHandler;
    private sourceRelatedSuggestionsHandler;
    private alternativeHelper;

    private logger = new Logger();
    private md = new MarkupHelper();

    private abortController;
    private sseReader: ReadableStreamDefaultReader;
    private lastStreamRenderTime = null;

    // this function is called at the end of a stream, possibly to continue the stream
    // - required by LLM function call system
    private endOfStreamAction: () => void;

    // recursion counter to prevent infinite loops
    // - required by LLM function call system
    private recursionCounter: number;

    constructor(
    ) {


        this.questionRelatedSuggestionsHandler = new QuestionRelatedSuggestionsHandler();
        this.sourceRelatedSuggestionsHandler = new SourceRelatedSuggestionsHandler();
        this.alternativeHelper = new AlternativeHelper();

        this.abort();
    }

    public async get(params: IRequestSearch): Promise<void> {

        if ([WidgetType.chat, WidgetType.cb].includes(get(ConfigStore).widgetType)) {

            if (get(ConfigStore).widgetType === WidgetType.cb && !get(state_showSearch) && !get(ConfigStore).greetingOnToggle)
                return;

            if (params.isGreetingRequest) {
                state_updatedAttributes.set(params.attributes);
                if (!ConversationHistoryHelper.isEmpty()) {
                    if (ConversationHistoryHelper.getLastTurn().is_greeting) {
                        //console.log("Regenerate greeting");
                        // clear history & regenerate greeting
                        ConversationHistoryHelper.clear();
                    } else {
                        //console.log("Greeting request when history already populated");
                        return;
                    }   
                }
            } else if (get(state_updatedAttributes) != null) {
                //console.log("Attributes stored from previous request");
                // merge the stored attributes into params.attributes
                params.attributes = { ...get(state_updatedAttributes), ...params.attributes };
                //state_updatedAttributes.set(null);
                //console.dir(params.attributes);
            }

            if (get(ConfigStore).streamMsg && !params.isGreetingRequest) { // no stream for greetings (due to Deepinfra bug?)
                await this.handleGetAnswerOnChatStream(params);
            } else {
                await this.handleGetAnswerOnChat(params)
            }

        } else {
            await this.handleGetAnswerOnQnA(params);
        }
    }

    /** get answer when the view isn't not chat */
    private async handleGetAnswerOnQnA(params: IRequestSearch): Promise<void> {

        if (!this.validateParams(params, 'getAnswerOnQnA')) return;

        let reqObj: IRequestSearch = {...params};

        try {

            state_searchString.set(params.query);
            state_searchSuggestion.set([]);
            this.questionRelatedSuggestionsHandler.clear();
            this.sourceRelatedSuggestionsHandler.clear();
            // this.store.set({ ...get(Store), searchSuggestion: [] });

            this.abortController?.abort();
            this.abortController = new AbortController();
            const res = await postQuestion(reqObj, this.abortController.signal)

            if (res?.ok) {
                const data: IAnswer = await res.json();

                state_searchResult.set(params?.isGreetingRequest ? { ...data, is_greeting: params?.isGreetingRequest } : data);

                if (!params?.skipAlternative)
                    this.alternativeHelper.get(params)

                this.questionRelatedSuggestionsHandler.get(data);
                this.sourceRelatedSuggestionsHandler.get(data)

            }


        } catch (e) {
            this.logger.error('AnswerHelper/getAnswerOnQnA', e)
        }

    }

    /** get answer when the view is chat */
    private async handleGetAnswerOnChat(params: IRequestSearch): Promise<void> {

        if (!this.validateParams(params, 'getAnswerOnChat')) return;

        this.recursionCounter = 0;
        let reqObj: IRequestSearch = {...params};

        try {

            const lastQuestion = ConversationHistoryHelper.getUnanswered();
            if (lastQuestion.respondedToId) {
                reqObj.response_to_question_id = lastQuestion.respondedToId;
            }
            if (lastQuestion.unrespondedCompilation && lastQuestion.unrespondedCompilation != params.query) {
                reqObj.query = lastQuestion.unrespondedCompilation + '\n' + params.query;
            }

            ConversationHistoryHelper.addQuestion(params.query, params?.isGreetingRequest, false);

            state_searchString.set('')
            state_searchSuggestion.set([])
            this.questionRelatedSuggestionsHandler.clear();
            this.sourceRelatedSuggestionsHandler.clear();

            await this.abortController?.abort();
            this.abortController = new AbortController();
            const res = await postQuestion(reqObj, this.abortController.signal)

            if (res?.ok) {
                let data: IAnswer = await res.json();

                let conversationItem = get(state_conversationResults);
                if (data.tool_calls && data.tool_calls.length > 0/*&& !data.answer*/) {
                    const funcr = await this.executeTools(data.tool_calls, data.question_id, false, params.attributes);
                    if (funcr) {
                        data.answer = funcr.answer;
                        data.question_id = funcr.questionId;
                    }
                }

                if (params?.isGreetingRequest)
                    data = { ...data, is_greeting: params?.isGreetingRequest };

                
                CitationHelper.addReferences(data);
                data.answer = this.md.render(data.answer);
                
                conversationItem[conversationItem.length - 1] = data;

                ConversationHistoryHelper.updateLastTurn(data);
                state_answerRating.set(null);

                // clear input field is not equal to question
                if (get(state_searchString) === data.question)
                    state_searchString.set('');


                this.questionRelatedSuggestionsHandler.get(data);
                this.sourceRelatedSuggestionsHandler.get(data);


                if (params?.isGreetingRequest) {
                    window.dispatchEvent(
                        new CustomEvent(WindowsCustomEventTypes.greetingInitCompleted));
                }

            }



        } catch (e) {
            ConversationHistoryHelper.hideLastTurnOnError(e);
            this.logger.error('AnswerHelper/getAnswerOnChat', e)
        }

    }

    /** get answer when the view is chat */
    private async handleGetAnswerOnChatStream(params: IRequestSearch): Promise<void> {

        if (!this.validateParams(params, 'getAnswerOnChatStream')) return;

        this.recursionCounter = 0;
        let reqObj: IRequestSearch = {...params};

        if (!!get(ConfigStore)?.serverConfig?.id) reqObj.config = get(ConfigStore)?.serverConfig?.id;

        try {

            await this.sseReader?.cancel();
            this.abortController?.abort();
            this.abortController = new AbortController();

            const lastQuestion = ConversationHistoryHelper.getUnanswered();
            if (lastQuestion.respondedToId) {
                reqObj.response_to_question_id = lastQuestion.respondedToId;
            }
            if (lastQuestion.unrespondedCompilation) {
                reqObj.query = lastQuestion.unrespondedCompilation + '\n' + params.query;
            }
            //console.log("STREAMING REQUEST - LAST CONV: " + JSON.stringify(lastQuestion));

            const turn: IAnswer = ConversationHistoryHelper.addQuestion(params.query, params?.isGreetingRequest, true);
            state_searchString.set('')
            state_searchSuggestion.set([])
            this.questionRelatedSuggestionsHandler.clear();
            this.sourceRelatedSuggestionsHandler.clear();

            await this.processQuestionStream(reqObj, turn);

            if (params?.isGreetingRequest) {
                window.dispatchEvent(
                    new CustomEvent(WindowsCustomEventTypes.greetingInitCompleted));
            }


        } catch (e) {
            ConversationHistoryHelper.hideLastTurnOnError(e);
            //ConversationHistoryHelper.stopStreaming();
            this.logger.error('AnswerHelper/getAnswerOnChatStream', e)
        }

    }

    private async processQuestionStream(reqObj: IRequestSearch, turn: IAnswer): Promise<void> {
        let res;

        addBusyApi(ApiTypes.streamPOSTQuestion);

        try {
            res = await postQuestionStream(reqObj, this.abortController.signal);
        } catch (e) {
            ConversationHistoryHelper.stopStreaming(turn.position_in_thread);
            if (e.name != 'AbortError') removeBusyApi(ApiTypes.streamPOSTQuestion);
            this.logger.debug("Stopping stream for thread item #" + turn.position_in_thread + " due to error: ", e.message);
            throw (e);
        }

        this.sseReader = <ReadableStreamDefaultReader>res?.body.getReader();

        let buffer = '';
        let eventBuffer = '';

        try {
            while (true) {
                const { value, done } = await this.sseReader?.read();

                if (done) {
                    //this.logger.debug("SSE - End of stream");
                    ConversationHistoryHelper.stopStreaming(turn.position_in_thread);
                    this.logger.debug("Stopping stream for thread item #" + turn.position_in_thread + " due to end of stream");
                    if (this.endOfStreamAction) {
                        const action = this.endOfStreamAction;
                        this.endOfStreamAction = null;
                        await action();
                    }
                    removeBusyApi(ApiTypes.streamPOSTQuestion);
                    break;
                }

                buffer += new TextDecoder().decode(value);

                let lines = buffer.split('\n');
                buffer = lines.pop() || '';  // Keep the last partial line in the buffer

                for (let line of lines) {
                    if (line.trim() === '') {
                        // End of an event
                        const eventData = eventBuffer.trim();
                        if (eventData) {
                            this.logger.debug("SSE: " + eventData);
                            try {
                                const data: IStreamQuestion = JSON.parse(eventData);
                                await this.processStreamMessage(data, turn, reqObj.attributes);
                    
                                if (data?.delta?.is_finished) {
                                     //this.logger.debug("SSE - End of delta");
                                    //removeBusyApi(ApiTypes.streamPOSTQuestion);
                                    await this.sseReader.cancel();
                                }
                            } catch (e) {
                                this.logger.error('SSE: Error parsing JSON', e);
                                this.logger.error("SSE: Erroneous JSON: " + eventData);
                            }
                        }
                        eventBuffer = '';
                    } else if (line.startsWith('data:')) {
                        eventBuffer += line.slice(5).trim() + '\n';
                    }
                }
            }
        } catch (e) {
            this.logger.error('SSE: Error processing stream', e);
            removeBusyApi(ApiTypes.streamPOSTQuestion);
            throw e;
            //removeBusyApi(ApiTypes.streamPOSTQuestion);
        }

/*

        this.sseReader = <ReadableStreamDefaultReader>res?.body.getReader();

        try {
            while (true) {
                const { value, done } = await this.sseReader?.read();

                if (done) {
                    //this.logger.debug("SSE: DONE");
                    removeBusyApi(ApiTypes.streamPOSTQuestion);
                    ConversationHistoryHelper.stopStreaming();
                    if (this.endOfStreamAction) {
                        const action = this.endOfStreamAction;
                        this.endOfStreamAction = null;
                        await action();
                    }
                    break;
                }

                let jsonString = new TextDecoder().decode(value);
                const dataItems = jsonString.split("data:");

                dataItems.forEach(async el => {

                    if (el.trim()) {

                        this.logger.debug("SSE: " + el);
                        try {
                            const data: IStreamQuestion = JSON.parse(el);
                            await this.processStreamMessage(data, turn);

                            if (data?.delta?.is_finished) {
                                //this.logger.debug("SSE delta finished");
                                removeBusyApi(ApiTypes.streamPOSTQuestion);
                                await this.sseReader.cancel();
                                return;
                            }
                        } catch (e) {
                            this.logger.error('SSE: Error parsing JSON', e)
                            this.logger.error("SSE: Erroneous JSON: " + el);
                        }
                    }

                });
            }
        } catch {
            removeBusyApi(ApiTypes.streamPOSTQuestion);
        }*/
    }

    /** method to abort pending operation */
    private abort(): void {

        /** check for type of action */
        const abortNow = (e): void => {
            if (get(state_busyApi).includes(ApiTypes.postQuestion)) {
                this.abortController?.abort();

            }
        }

        [WindowsCustomEventTypes.requestPostOption].forEach(evt => {
            window.addEventListener(evt, abortNow)
        });
    }

    private validateParams(params: IRequestSearch, type: string): boolean {
        try {

            /** query cannot be empty */
            if (typeof params !== 'object' || !params.hasOwnProperty('query') && !params?.isGreetingRequest) {
                this.logger.warn(`${type} - incomplete argument`, params)
                return false;
            }


            return true;

        } catch (e) {
            this.logger.warn(`${type} - invalid argument`, e)
            return false;
        }
    }

    private async processStreamMessage(data: IStreamQuestion, conversationTurn: IAnswer, attributes: any): Promise<void> {

        //let conversationTurn: IAnswer = ConversationHistoryHelper.getLastTurn();
        //console.log('CT1:');console.log(conversationTurn);
        //console.log('CTD:');console.log(data);

        const delta = { ...data.delta };
        delete data.delta;
        delete data.question; // otherwise the response payload overwrites the question, which is no good if the question is a function call

        if (delta.tool_calls) {
            data.tool_calls = delta.tool_calls;
        }

        if (data.tool_calls) {
            //console.log("Tool calls: " + JSON.stringify(data.tool_calls));
            if (conversationTurn.tool_calls) {
                //console.log("Existing tool calls: " + JSON.stringify(conversationTurn.tool_calls));
                // merge conversationTurn.tool_calls with data.tool_calls
                data.tool_calls.forEach(tc => {
                    let matchingTC = conversationTurn.tool_calls.find(t => t.index == tc.index);
                    if (matchingTC) {
                        if (matchingTC.function == null && tc.function != null) {
                            matchingTC.function = tc.function;
                        } else {
                            matchingTC.function.name = (matchingTC.function.name != null || tc.function.name != null) ? (matchingTC.function.name || "") + (tc.function.name || "") : null;
                            matchingTC.function.arguments = (matchingTC.function.arguments != null || tc.function.arguments != null) ? (matchingTC.function.arguments || "") + (tc.function.arguments || "") : null;
                        }
                    } else {
                        conversationTurn.tool_calls.push(tc);
                    }
                });

            } else {
                conversationTurn.tool_calls = data.tool_calls;
            }
        }

        /*if (data.tool_calls && !delta.answer) {
            if (!delta.answer && conversationTurn.answer_chunks.length == 0) {
                const funcr = await this.executeTools(data.tool_calls, data.question_id ? data.question_id : '', get(ConfigStore).sendFunctionResult);
                this.logger.debug('Returning JavaScript function results', funcr);
                if (funcr != null) {
                    data.answer = funcr.answer;
                    data.question_id = funcr.questionId;
                } 
            } else {
                this.logger.warn("Function call is combined with text response", data.tool_calls);
            }
        }*/

        if (data.stream) {
            //conversationTurn = { ...conversationTurn, is_streaming: true, ...data };
            conversationTurn.is_streaming = true;
            conversationTurn.document_data = data.document_data;
            conversationTurn.document_id = data.document_id;
        }

        if (delta.answer) {
            let newChunk = { content: delta.answer, ts: data.ts };
            let previousChunk = conversationTurn.answer_chunks.length > 0 ? conversationTurn.answer_chunks[conversationTurn.answer_chunks.length - 1] : null;

            conversationTurn.answer_chunks.push(newChunk);
            if (previousChunk && previousChunk.ts > newChunk.ts) {
                // Sort the chunk array based on the timestamp in ascending order
                conversationTurn.answer_chunks.sort((a, b) => a.ts - b.ts);

                //console.log("PreviousTS: " + previousChunk?.content + " - " + previousChunk?.ts);
                //console.log("newTS: " + newChunk.content + " - " + newChunk.ts);

                // Extract content and join them
                CitationHelper.addReferencesAndAnswer(conversationTurn);
                
            } else {
                CitationHelper.addReferencesChunked(conversationTurn, newChunk);
            }

            // Extract content and join them
            //const resultString = conversationTurn.answer_chunks.map(chunk => chunk.content).join('');

            // Update the answer
            //conversationTurn.answer = resultString;

        }

        if (delta.is_finished) {

            // clear input field is not equal to question
            if (get(state_searchString) === data.question)
                state_searchString.set('');

            state_answerRating.set(null);

            /*switch (get(ConfigStore).filterCitations) {
                case 'no':
                    conversationTurn = { ...conversationTurn, is_streaming: false, ...data, is_streamError: delta?.error };
                    break;
                case 'link':
                    {
                        //console.log ("final run: " + conversationTurn.answer_chunks.map(chunk => chunk.content).join(''));
                        let extracted: { text: string, ids: string[] } = enrichDocumentCitations(conversationTurn.answer_chunks.map(chunk => chunk.content).join(''), conversationTurn.document_id, conversationTurn.document_data);
                        //console.log(extracted);
                        let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(extracted.text);
                        //console.log(scanned);
                        selectDocumentIdsInAnswer(conversationTurn, extracted.ids); // remove unused documents from answer
                        conversationTurn = { ...conversationTurn, answer: scanned.textWithoutCitation, has_unfinished_citation: scanned.result, is_streaming: false, ...data, is_streamError: delta?.error };
                    }
                    break;    
                case 'remove':
                    {
                        let extracted: { text: string, ids: string[] } = extractDocumentCitations(conversationTurn.answer_chunks.map(chunk => chunk.content).join(''), conversationTurn.document_id, conversationTurn.document_data);
                        let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(extracted.text);
                        selectDocumentIdsInAnswer(conversationTurn, extracted.ids); // remove unused documents from answer
                        conversationTurn = { ...conversationTurn, answer: scanned.textWithoutCitation, has_unfinished_citation: scanned.result, is_streaming: false, ...data, is_streamError: delta?.error };
                    }
                    break;
            }*/

            conversationTurn = { ...conversationTurn, is_streaming: false, ...data, is_streamError: delta?.error };
            
            //console.log('CTF:');console.dir(conversationTurn);

            if (conversationTurn.tool_calls && conversationTurn.tool_calls.length > 0) {
                if (conversationTurn.answer && conversationTurn.answer.length > 0) {
                    this.logger.warn("Function call is combined with text response: " + conversationTurn.answer, data.tool_calls);
                }
                const funcr = await this.executeTools(conversationTurn.tool_calls, conversationTurn.question_id, true, attributes);
                this.logger.debug('Returning tool call results', funcr);
                /*if (funcr) {
                    conversationTurn.answer = funcr.answer;
                    conversationTurn.question_id = funcr.questionId;
                }*/
            }

            this.logger.debug("Formatting final answer...");
            conversationTurn.answer = data.answer || conversationTurn.answer_chunks.map(chunk => chunk.content).join(''); // if data.answer exists, this was a cached response and there are not answer_chunks
            CitationHelper.addReferences(conversationTurn);
            conversationTurn.answer = this.md.render(conversationTurn.answer);

            this.questionRelatedSuggestionsHandler.get(conversationTurn);
            this.sourceRelatedSuggestionsHandler.get(conversationTurn);

            ConversationHistoryHelper.updateLastTurn(conversationTurn);
        } else {
            if ((this.lastStreamRenderTime == null || Date.now() - this.lastStreamRenderTime > 100) && !conversationTurn.has_unfinished_citation) {
                this.lastStreamRenderTime = Date.now();

                //conversationTurn.answer = conversationTurn.answer || conversationTurn.answer_chunks.map(chunk => chunk.content).join('');
                //console.log("Answer: " + conversationTurn.answer);
                //conversationTurn.answer = conversationTurn.answer.replace(/[\[\(][^\]\)]*(?!.*[\]\)])/g, '')/*opening brackets without closing ones*/.replace(/\[(?:\d+[-,\s]*)+\]/g, '')/*citations*/;
                conversationTurn.answer = this.md.render(conversationTurn.answer);

                ConversationHistoryHelper.updateLastTurn(conversationTurn);
            }
        }

    };

    private async executeTools(toolCalls: IToolCall[], questionId: string, isStreaming: boolean, attributes: any): Promise<{ answer: string, questionId: string } | null> {
        this.logger.debug('Executing JavaScript function(s)', toolCalls);
        const gqa_functions = window[get(ConfigStore).functionObjectName];
        try {
            // STREAMING / RETURN ====================
            if (isStreaming) {
                this.logger.debug("Add end-of-stream action...", questionId);
                let localQuestionId: string = questionId;
                this.endOfStreamAction = async () => {
                    this.logger.debug("End-of-stream action triggered");
                    let results: IToolResponse[] = await Promise.all(toolCalls.map(async (toolCall) => {
                        if (toolCall.function) {
                            const jsfunc = gqa_functions && gqa_functions[toolCall.function.name];
                            if (!jsfunc) {
                                this.logger.warn('JavaScript function not found', toolCall.function.name);
                                return new Promise((resolve) => {
                                    setTimeout(() => {
                                        this.logger.warn('run timout');
                                        resolve({ run: toolCall });
                                    }, 500);
                                });
                            } else {
                                return new Promise((resolve) => {
                                    setTimeout(async () => {
                                        this.logger.warn('run timout');
                                        resolve({ response: await jsfunc(toolCall.function.arguments ? JSON.parse(toolCall.function.arguments) : null), run: toolCall });
                                    }, 500);
                                });
                            }
                        } else {
                            this.logger.error('Non-function tools are not supported', toolCall.type);
                            return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve({ response: "ERROR" });
                                }, 500);
                            });
                        }
                    }));
                    
                    if (++this.recursionCounter > 3) {
                        this.logger.error('Error executing a JavaScript function', 'Recursion limit reached');
                    } else {
                        this.logger.warn('Forwarding JavaScript function results to server', results);
                        this.logger.warn('for question ID', localQuestionId);
                        const reqObj: IRequestSearch = {
                            query: JSON.stringify(results),
                            response_to_question_id: localQuestionId,
                            isGreetingRequest: false,
                            attributes,
                            type: 'tool'
                        };
                        await this.processQuestionStream(reqObj, ConversationHistoryHelper.addQuestion("", false, true));
                    }
                }
                return null; // if null, add another turn to the end of the stream
            // NON-STREAMING ====================
            } else {
                let results: any[] = await Promise.all(toolCalls.map(async (toolCall) => {
                    if (toolCall.function) {
                        const jsfunc = gqa_functions && gqa_functions[toolCall.function.name];
                        if (!jsfunc) {
                            this.logger.warn('JavaScript function not found', toolCall.function.name);
                            /*return new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve("ERROR");
                                }, 500);
                            });*/
                            return { run: toolCall };
                        } else {
                            return { response: await jsfunc(toolCall.function.arguments ? JSON.parse(toolCall.function.arguments) : null), run: toolCall };
                        }
                    } else {
                        this.logger.error('Non-function tools are not supported', toolCall.type);
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                resolve({ response: "ERROR" });
                            }, 500);
                        });
                    }
                }));
                if (++this.recursionCounter > 3) {
                    this.logger.error('Error executing a JavaScript function', 'Recursion limit reached');
                    return { answer: '', questionId };
                } else
                    if (get(ConfigStore).sendFunctionResult) {
                        this.logger.debug('Forwarding JavaScript function results to server (non-streaming)', results);
                        const reqObj: IRequestSearch = {
                            query: JSON.stringify(results),
                            response_to_question_id: questionId,
                            isGreetingRequest: false,
                            attributes,
                            type: 'tool'
                        };
                        if (get(ConfigStore).functionResultConfig) reqObj.config = get(ConfigStore).functionResultConfig;
                        const aiResponse = await postQuestion(reqObj, this.abortController.signal);
                        if (aiResponse?.ok) {
                            let data: IAnswer = await aiResponse.json();
                            if (data.tool_calls && !data.answer && data.tool_calls.length > 0) {
                                this.logger.warn("Recursively calling on a JavaScript function", data.tool_calls);
                                const funcr = await this.executeTools(data.tool_calls, data.question_id, false, attributes);
                                if (funcr) {
                                    data.answer = funcr.answer;
                                    data.question_id = funcr.questionId;    
                                } else {
                                    data.answer = '';
                                    data.question_id = questionId;
                                }
                            }
                            return { answer: data.answer, questionId: data.question_id };
                        } else {
                            this.logger.error('Error sending JavaScript function result back to server');
                            return { answer: '', questionId };
                        }
                    } else {
                        if (typeof results !== 'undefined' && results !== null && results.length > 0) {
                            return { answer: results.length == 1 ? results[0].toString() : results.toString(), questionId };
                        } else {
                            return { answer: '', questionId };
                        }
                    }
            }
        } catch (e) {
            this.logger.error('Error executing a JavaScript function', e);
            return { answer: '', questionId };
        }
    }

}


