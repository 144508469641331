import { get } from "svelte/store";
import { ConfigStore } from "../store/config";

/** method to update the config store   */
export function updateConfigStore(x: any): void {

    const newConfig: any = {};

    for (let key in x) {
        if (get(ConfigStore).hasOwnProperty(key)) {

            switch (key) {
                case 'customTheme':
                    newConfig[key] = updateInlineCssObj(x[key])
                    break;

                default:
                    newConfig[key] = x[key]
                    break;
            }

        }

    }



    if (!!Object.keys(newConfig).length)
        ConfigStore.set({ ...get(ConfigStore), ...newConfig });

}

export function updateInlineCssObj(x: any): any {
    const cssConfig: any = {}
    for (let key in x) {
        if (get(ConfigStore).customTheme?.hasOwnProperty(key))
            cssConfig[key] = x[key]
    }

    return cssConfig;

}