<!-- <svelte:options customElement="clever-search-chat-result-item" /> -->

<script lang="ts">
  import { beforeUpdate, onMount } from "svelte";
  import { ApiTypes } from "../enums/api.enum";
  import { ConfigStore } from "../store/config";
  import { state_busyApi, state_conversationResults } from "../store/state";
  import { shadowDomUpdates } from "../helpers/shadowdom-updates";
  import { lazyLoad } from '../actions/lazy-img-load';

  import BusyComponent from "./busy.svelte";
  import RatingComponent from "./rating.svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  export let index;

  $: isSearchBusy = $state_busyApi.includes(ApiTypes.postQuestion);

  $: conversation = $state_conversationResults[index];

  $: isHidden = conversation?.hideOnChat;

  let dataSource: {
    sourceId: string;
    content: string[];
    title: string;
    url: string;
    image_url?: string;
    localId?: number;
  }[] = [];

  beforeUpdate(() => {
    if (!dataSource.length) isMultipleSources();
  });

  const isMultipleSources = () => {
    //console.log("isMultipleSources", conversation);
    if (conversation?.is_streaming) return; // only check at the end of the stream
    try {
      dataSource = [];
      for (let item of conversation.document_data) {
        //console.log("item", item);
        const hasSourceIndex = dataSource.findIndex(
          (x) => x.sourceId === item?.source_id && !!item?.url?.length
        );

        if (hasSourceIndex > -1) {
          //console.log("hasSourceIndex", hasSourceIndex);
          dataSource[hasSourceIndex].content.push(item.content);
        } else {
          //console.log("item.url.length", item?.url?.length);
          if (!!item?.url?.length)
            dataSource.push({
              sourceId: item.source_id,
              content: [item.content],
              title: item.title,
              url: item.url,
              localId: item.local_id,
              image_url: item?.image_url,
            });
        }
      }

      dataSource.sort((a, b) => a.localId - b.localId);
    } catch {
      dataSource = [];
    }
  };


  const sourcesHasImages = (sources): boolean => {
    return sources.findIndex((x) => x?.image_url) > -1;
  };
</script>

{#if !!conversation && !isHidden}
  <div class="cs-chat-item">
    {#if conversation.question && !conversation.is_greeting}
      <div
        class="cs-q cs-fadeInFly-1s"
        style:background={$ConfigStore.customTheme.msgBubbleUserBg}
        style:color={$ConfigStore.customTheme.msgBubbleUserTextColor}
      >
        {conversation.question}
      </div>
    {/if}
    <!-- {#if conversation.answer} -->
    <div
      class="cs-a-wrapper cs-fadeInFly-1s"
      class:cs-stream={$ConfigStore.streamMsg}
    >
      <!--{#if conversation.is_streaming && !conversation.answer}
        <div
          class="cs-a"
          style:background={$ConfigStore.customTheme.msgBubbleAgentBg}
          style:color={$ConfigStore.customTheme.msgBubbleAgentTextColor}
        >
          <div in:fade|global>
            <BusyComponent />
          </div>
        </div>
      {:else if conversation.answer}-->{#if conversation.answer}
        <div
          class="cs-a"
          style:background={$ConfigStore.customTheme.msgBubbleAgentBg}
          style:color={$ConfigStore.customTheme.msgBubbleAgentTextColor}
        >
          {@html conversation.answer}
          {#if conversation?.is_streamError}<div class="cs-msg-cancelled">
              ({conversation?.is_streamError})
            </div>{/if}
          {#if conversation?.is_streamCancelled}
            <span class="cs-msg-cancelled">(...)</span>{/if}
          {#if dataSource.length && !conversation.is_greeting && !conversation?.is_streaming && !conversation?.is_streamCancelled}
            <div class="cs-a-source-wrapper" in:fade|global>
              {#if !sourcesHasImages(dataSource)}
                <div
                  class="cs-title"
                  style:color={$ConfigStore.customTheme.msgBubbleAgentTextColor}
                >
                  {$_("chat_result_item.read_more")}
                </div>
              {/if}
              {#each dataSource as sourceItem}
                {#if !sourceItem?.image_url?.length}
                  <a
                    target={$ConfigStore.hyperlinkTargetType}
                    href={sourceItem.url}
                    class={sourceItem?.image_url?.length ? "img-on" : ""}
                  >
                    {#if sourceItem.localId}
                      <span class="cs-citation-number"
                        >{sourceItem.localId}</span
                      >
                    {/if}
                    <span class="cs-source-title">{sourceItem.title}</span>
                  </a>
                {/if}
              {/each}
              {#if sourcesHasImages(dataSource)}
                <div class="cs-img-source-wrapper">
                  {#each dataSource as sourceItem}
                    {#if sourceItem?.image_url?.length}
                      <a
                        target={$ConfigStore.hyperlinkTargetType}
                        href={sourceItem.url}
                        class={sourceItem?.image_url?.length ? "cs-img-on" : ""}
                      >
                        <img use:lazyLoad={sourceItem.image_url} alt="" />

                        {#if sourceItem.localId}
                          <span class="cs-citation-number"
                            >{sourceItem.localId}</span
                          >
                        {/if}
                        <span class="cs-source-title">{sourceItem.title}</span>
                      </a>
                    {/if}
                  {/each}
                </div>
              {/if}
            </div>
          {/if}
        </div>

        {#if index == $state_conversationResults.length - 1 && !conversation.is_greeting && !conversation?.is_streaming && !conversation?.is_streamCancelled}
          <div in:fade|global={{ delay: 3000 }}>
            <RatingComponent />
          </div>
        {/if}
      {/if}
    </div>
    <!-- {/if} -->
  </div>
{/if}

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}
-->
<style lang="scss">
  @import "./../../scss/components/chat-wrapper";
</style>
