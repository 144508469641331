import { get } from "svelte/store";
import { ConfigStore } from "../store/config";

/** font face doest not work inside web component, need to be appended to html file */
export function patchFontIcoMoonFont(): void {

    const baseUrl = get(ConfigStore).assetBaseUrl || get(ConfigStore).apiBaseUrl;
    // Your CSS as text
    var styles = `@font-face {font-family: "cs-icomoon";src: url("${baseUrl ? baseUrl : ''}/fonts/icomoon.eot?us1arm#iefix") format("embedded-opentype"), url("${baseUrl ? baseUrl : ''}/fonts/icomoon.ttf") format("truetype"), url("${baseUrl ? baseUrl : ''}/fonts/icomoon.woff") format("woff"),url("${baseUrl ? baseUrl : ''}/fonts/icomoon.svg#icomoon") format("svg"); font-weight: normal; font-style: normal;font-display: block;}`

    var styleSheet = document.createElement("style")
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet);
}