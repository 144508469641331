<svelte:options customElement="clever-search-chat" />

<script lang="ts">
    import { onMount } from "svelte";
    import { WidgetType } from "./lib/enums/widget-type.enum";
    import { WindowsCustomEventTypes } from "./lib/enums/windows-custom-event.enum";
    import { shadowDomUpdates } from "./lib/helpers/shadowdom-updates";
    /** stores */
    import { ConfigStore } from "./lib/store/config";
    import {
        state_answerRating,
        state_conversationResults,
        state_questionRelatedSearchSuggestions,
        state_searchResult,
        state_sourceRelatedSearchSuggestions,
        state_apiErrors
    } from "./lib/store/state";

    import SearchInput from "./lib/components/search-input.svelte";
    import ChatWrapper from "./lib/components/chat-wrapper.svelte";
    import { fade } from "svelte/transition";

    let contentWrapper: HTMLElement;

    let scrollTimer: any;

    // to make sure the config is correct, update view type
    // however, cb load chat, so avoid on cb
    if ($ConfigStore.widgetType !== WidgetType.cb)
        $ConfigStore = { ...$ConfigStore, widgetType: WidgetType.chat };

    $: logoSrc = getLogoImg;

    const getLogoImg = (): string => {
        if ($ConfigStore.agentImg) return $ConfigStore.agentImg;

        if ($ConfigStore.logoMini?.length) {
            if ($ConfigStore.logoMini?.substring(0, 4) == "http")
                return $ConfigStore.logoMini;

            return (
                ($ConfigStore.assetBaseUrl || $ConfigStore.apiBaseUrl) +
                $ConfigStore.logoMini
            );
        }

        if ($ConfigStore.logoMain?.length) {
            if ($ConfigStore.logoMain?.substring(0, 4) == "http")
                return $ConfigStore.logoMain;

            return (
                ($ConfigStore.assetBaseUrl || $ConfigStore.apiBaseUrl) +
                $ConfigStore.logoMain
            );
        }

        return "";
    };
    onMount(() => {
        shadowDomUpdates("clever-search-chat", true);
    });

    const restartConversation = () => {
        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.restartThread, {
                detail: {
                    configOptions: $ConfigStore,
                    serverConfig: $ConfigStore.serverConfig,
                },
            }),
        );

        state_conversationResults.set([]);
        state_searchResult.set(null);
        state_questionRelatedSearchSuggestions.set(null);
        state_sourceRelatedSearchSuggestions.set(null);
        state_answerRating.set(null);
        state_apiErrors.set([]);
    };

    // conversation results state subscription to scroll new items to screen
    state_conversationResults.subscribe((x) => {
        if (contentWrapper) {
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => {
                //console.log("SCROLLING TO BOTTOM", contentWrapper.scrollHeight);
                contentWrapper.scrollTop = contentWrapper.scrollHeight;
                clearTimeout(scrollTimer);
            }, 10);
        }
    });
</script>

<div
    class={`cs-chat-wrapper cs-type-${$ConfigStore.widgetType} ${$ConfigStore.showBranding ? "cs-branding-on" : ""}`}
    style:background={$ConfigStore.customTheme.bodyBg}
    style:border-color={$ConfigStore.customTheme.bodyBorderColor}
    in:fade|global
>
    <div class="cs-content-wrapper" bind:this={contentWrapper}>
        <ChatWrapper />
    </div>
    <div class="cs-input-wrapper">
        <div>
            {#if $ConfigStore.canClearChatHistory}
                <div class="logo">
                    {#if $state_conversationResults.length}
                        <button
                            in:fade|global
                            on:click={() => restartConversation()}
                            ><span class="icon-broom" /></button
                        >
                    {:else}
                        <!--><img
                            in:fade|global
                            src={logoSrc()}
                            alt={$ConfigStore.productName}
                        />-->
                        <button
                            in:fade|global
                            style="opacity: 0.2"
                            disabled
                            ><span class="icon-broom" /></button
                        >
                    {/if}
                </div>
            {/if}

            <SearchInput />
        </div>
        {#if $ConfigStore.showBranding}
            <div class="cs-branding-wrapper">
                Powered by
                <a href="https://www.conversagent.app/" target="_blank"
                    ><img
                        alt="Conversagent"
                        src="https://components.clevertar.app/gqa/conversagent-logo.png"
                    /> Conversagent</a
                >
            </div>
        {/if}
    </div>
</div>
{#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}

<style lang="scss">
    @import "./scss/pages/chat";
</style>
