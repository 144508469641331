
import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { WindowsCustomEventTypes } from "../../enums/windows-custom-event.enum";
import type { IRequestSearchSuggestion } from "../../interfaces/request-search-suggestion.interface";
import { state_busyApi, state_searchSuggestion } from "../../store/state";
import { Logger } from "../../telemetry/logger";
import { GetSuggestions } from "../api/get-suggestions";

export class SuggestionHandler {


    private logger = new Logger();;
    private abortController

    constructor() {

        this.abort()
    }

    public async get(params: IRequestSearchSuggestion) {

        if (
            /** avoid requesting when query is empty */
            !params?.query?.length) {
            return;
        }
        try {

            this.abortController?.abort();
            this.abortController = new AbortController();

            const res = await GetSuggestions(params, this.abortController.signal);

            if (res?.ok) {
                const searchSuggestion = await res.json();
                state_searchSuggestion.set(searchSuggestion.hits);

            }

        } catch (e) {

            this.logger.error('SuggestionHandler/get', e)
        }
    }

    /** method to abort pending operation */
    private abort(): void {

        /** check for type of action */
        const abortNow = (e): void => {
            if (get(state_busyApi).includes(ApiTypes.getSuggestions))
                this.abortController?.abort();
        }

        /** cancel request when user clears chat history */
        [
            WindowsCustomEventTypes.requestPostOption,
            WindowsCustomEventTypes.requestSearch,
            WindowsCustomEventTypes.updateRating
        ].forEach(evt =>
            window.addEventListener(evt, abortNow)
        );
    }

}