<script lang="ts">
    import { ApiTypes } from "../enums/api.enum";
    import { HandledErrorTypes } from "../enums/handled-errors.enum";
    import {
        state_apiErrors,
        state_busyApi,
        state_conversationResults,
        state_questionRelatedSearchSuggestions,
        state_sourceRelatedSearchSuggestions,
    } from "../store/state";
    import { ConfigStore } from "./../store/config";

    import { fade, fly } from "svelte/transition";
    import BusyComponent from "./busy.svelte";
    import ChatResultItemComponent from "./chat-result-item.svelte";
    import FaqComponent from "./faq.svelte";
    import QuestionRelatedSuggestionComponents from "./question-related-search.svelte";
    import SourceRelatedSuggestionComponents from "./source-related-search-suggestions.svelte";
    import { _ } from "svelte-i18n";

    $: isSearchBusy = $state_busyApi.some((x) =>
        [
            ApiTypes.postQuestion,
            ApiTypes.streamPOSTQuestion, // added 07/24
            ApiTypes.postOption,
            ApiTypes.getOption,
        ].includes(x)
    );

    $: postQuestionApiError = $state_apiErrors.find((x) =>
        [ApiTypes.postQuestion, ApiTypes.streamPOSTQuestion].includes(x.apiType)
    );
</script>

{#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if}
{#if !!$ConfigStore.apiBaseUrl}
    <div class={"chat-item-wrapper cs-type-" + $ConfigStore.widgetType}>
        {#if $state_conversationResults.length}
            {#each $state_conversationResults as item, i}
                <ChatResultItemComponent index={i} />
            {/each}

            {#if $state_conversationResults.length == 1 && $state_conversationResults[0].is_greeting}
                <div class="cs-search-faq" in:fade|global>
                    <FaqComponent />
                </div>
            {/if}
        {:else}
            <div class="cs-search-faq" in:fade|global>
                <FaqComponent />
            </div>
        {/if}

        {#if isSearchBusy}
            <div class="cs-search-loading-chat" in:fade|global>
                <BusyComponent />
            </div>
        {:else if postQuestionApiError}
            {#if postQuestionApiError.error == HandledErrorTypes.GPTContentFilterError}
                <div class="cs-error-msg">
                    {$_("chat_wrapper.error_content_comply")}
                </div>
            {:else if postQuestionApiError.status == 429}
                <div class="cs-error-msg">
                    {$_("chat_wrapper.error_too_many_requests")}
                </div>
            {:else if postQuestionApiError.errorType != "AbortError"}
                <div class="cs-error-msg">
                    {$_("chat_wrapper.error_abort_error")}
                </div>
            {/if}
        {:else if $state_conversationResults.length && !isSearchBusy}
            {#if $ConfigStore.serverConfig?.question_prompts?.includes("suggested_search") && $state_questionRelatedSearchSuggestions}
                <div in:fly|global>
                    <QuestionRelatedSuggestionComponents />
                </div>
            {/if}
            {#if $ConfigStore.serverConfig?.document_prompts?.includes("suggested_search") && $state_sourceRelatedSearchSuggestions}
                <div in:fly|global>
                    <SourceRelatedSuggestionComponents />
                </div>
            {/if}
        {/if}
    </div>
{/if}

<style lang="scss">
    @import "./../../scss/components/chat-wrapper";
    @import "./../../scss/shared/_error";

    .cs-error-msg {
        margin: 10px 15px 0;
    }
</style>
