import type { ISessionStorageData } from "../interfaces/session-storage";

const storageId = 'gqa';

export const SessionStoreHandler = {
    update: (data: Partial<ISessionStorageData>): void => {
        const existingData = sessionStorage.getItem(storageId);
        let newData = {};
        if (existingData) {
            newData = { ...JSON.parse(existingData), ...data };
        } else {
            newData = data;
        }

        sessionStorage.setItem(storageId, JSON.stringify(newData));
    },
    get: (): any => {
        const data = sessionStorage.getItem(storageId);
        return data ? JSON.parse(data) : null;
    },
    remove: (key: keyof ISessionStorageData): void => {
        const existingData = sessionStorage.getItem(storageId);

        if (existingData) {
            let newData = JSON.parse(existingData);

            if (newData[key])
                delete newData[key]

            sessionStorage.setItem(storageId, JSON.stringify(newData));
        }
    }
}