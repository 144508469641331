import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { addApiError, addBusyApi, removeBusyApi } from "../../helpers/handle-api";
import type { IRequestSearchAlternative } from "../../interfaces/request-search-alternative.interface";
import { ConfigStore } from "../../store/config";
import { state_apiErrors } from "../../store/state";

/** function to fetch POST /1/search api end point */
export async function PostSearch(params: IRequestSearchAlternative): Promise<any> {

    try {

        addBusyApi(ApiTypes.postSearch);
        state_apiErrors.set(get(state_apiErrors).filter(x => x.apiType !== ApiTypes.postSearch));

        let url = new URL(`${get(ConfigStore).apiBaseUrl}/1/search`);

        if (!!get(ConfigStore)?.serverConfig?.tenant_id)
            url.searchParams.set('tenant', get(ConfigStore)?.serverConfig?.tenant_id);

        return fetch(url.href, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then((res) => {
                if (res?.ok)
                    return res;

                throw (res);
            })
            .catch((res) => {

                /** error api */
                addApiError({
                    apiType: ApiTypes.postSearch,
                    status: res?.status,
                    statusText: res?.statusText,
                    error: res?.error,
                    errorType: res?.type
                });

                throw (res);
            })
            .finally(() => {
                removeBusyApi(ApiTypes.postSearch);
            });

    } catch (e) {
        removeBusyApi(ApiTypes.postSearch);
        throw (e);
    }
}
