import { writable } from "svelte/store";
import { RatingTypes } from "../enums/question-rating.enum";
import type { IConfigStore } from "../interfaces/store-config.interface";

export const ConfigStore = writable<IConfigStore>({
    productName: 'GeniusQA',
    widgetType: null,
    noGreeting: false,
    widgetParentEle: null,
    apiBaseUrl: null,
    assetBaseUrl: '',
    logoMain: '',
    logoMini: '',
    toggleSrcHtml: null,
    toggleSrcImg: null,
    toggleInitShowDelay: 0,
    togglePosition: null,
    customCSSUrl: '',
    customInlineCSS: '',
    speechInputOn: true,
    agentName: 'Grace',
    agentTagline: 'AI Assistant',
    searchSuggestion: false,
    showFAQ: false,
    canClearChatHistory: false,
    customAttributes: null,
    agentImg: null,
    serverConfig: null,
    streamMsg: false,
    injectDatetime: 'never',
    filterCitations: 'no',
    loadThreadHistory: false,
    sendFunctionResult: true,
    functionResultConfig: null,
    functionObjectName: 'gqa_functions',
    greetingOnToggle: false,
    greetingOnToggleDelay: 5000,
    greetingExpiryTime: 21600000,
    customTheme: {
        msgBubbleUserBg: null,
        msgBubbleUserTextColor: null,
        msgBubbleAgentBg: null,
        msgBubbleAgentTextColor: null,
        badgeBg: null,
        badgeIconColor: null,
        bodyBg: null,
        bodyBorderColor: null,
        headerBg: null,
        headerTextColor: null,
        launchRightPos: '20px',
        launchBottomPos: '10px',
    },
    showBranding: false,
    headerCustomHtml: '',

    // depreciate [todo]
    ratingType: RatingTypes.thumbs,
    hyperlinkTargetType: '_self',
    alternativeReqCount: 5,
    showAnswerDesc: true,
    showAnswerSourceLink: false,
    toggleAlternative: true,
    showDocDataOnNoAnswer: false,
    ratingDelay: 5000,
    searchStringMinLength: 1,
    searchStringMaxLength: 200,
    ratingNegativeSuggestion: ['Incorrect answer', 'Not enough information', 'Answer is too long', 'Site is too slow', 'Hard to use', 'Other'],
    showSelectAllIndices: true,
    mediaSharing: true,
    excludeMediaSharing: [],
})


