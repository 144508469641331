<!-- <svelte:options customElement="clever-search-rating-extend" /> -->

<script lang="ts">
    import { _ } from "svelte-i18n";
    import { ApiTypes } from "../enums/api.enum";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import type { IUpdateRating } from "../interfaces/request-update-rating-question.interface";
    import { state_answerRating, state_busyApi } from "../store/state";
    import { ConfigStore } from "./../store/config";

    $: isUpdating = $state_busyApi.includes(ApiTypes.putRating);

    const sendRating = (val: any) => {
        const data: IUpdateRating = { ...$state_answerRating, content: val };

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.updateRating, {
                detail: data,
            })
        );
    };
</script>

{#if $state_answerRating?.score == 0 && $ConfigStore.ratingNegativeSuggestion.length}
    <div class="cs-rating-negative-title">
        {$_("rating_extend.what_went_wrong")}
    </div>
    <div class="cs-rating-negative-panel">
        <div>
            {#each $ConfigStore.ratingNegativeSuggestion as negativeItem}
                <button
                    class:active={$state_answerRating.content == negativeItem}
                    on:click={() => sendRating(negativeItem)}
                    disabled={isUpdating}>{negativeItem}</button
                >
            {/each}
        </div>
    </div>
{/if}

<!-- <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" /> -->

<style lang="scss">
    @import "./../../scss/components/_rating-extend";
</style>
