<svelte:options customElement="clever-search-question-related-search" />

<script lang="ts">
    import { onMount } from "svelte";
    import { WidgetType } from "../enums/widget-type.enum";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import { shadowDomUpdates } from "../helpers/shadowdom-updates";
    import type { IRequestSearch } from "../interfaces/request-search-question.interface";
    import { ConfigStore } from "../store/config";
    import {
        state_conversationResults,
        state_questionRelatedSearchSuggestions,
        state_searchResult,
        state_selectedIndicesList,
    } from "../store/state";
    import { _ } from "svelte-i18n";

    $: list = $state_questionRelatedSearchSuggestions?.data
        ? $state_questionRelatedSearchSuggestions?.data
              .split("\n")
              .map(m => m.trim().replace(new RegExp(/^•|^-|^\*|^\d+\./gm, "g"), ""))
              .filter(x => x.length > 0)
              //.sort(() => 0.5 - Math.random())
        : [];

    $: currentQuestion = [WidgetType.chat, WidgetType.cb].includes(
        $ConfigStore.widgetType
    )
        ? $state_conversationResults[$state_conversationResults.length - 1]
              ?.question
        : $state_searchResult.question;

    onMount(() => {
        shadowDomUpdates("clever-search-question-related-search", true);
    });

    const searchNow = (x: string): void => {
        const data: IRequestSearch = {
            //query: x.replace(new RegExp(/•|-/gm, "g"), ""),
            query: x,
        };

        if (
            $state_selectedIndicesList.length &&
            ![WidgetType.chat, WidgetType.cb].includes($ConfigStore.widgetType)
        )
            data.index = $state_selectedIndicesList
                .toString()
                .replace(", ", "");

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                detail: data,
            })
        );
    };
</script>

{#if list && list.length > 0}
    <div class={"cs-related-search cs-type-" + $ConfigStore.widgetType}>
        <div class="cs-related-search-title">
            {$_("question_related_search.related_search")}
        </div>
        <div class="cs-related-search-item">
            {#each list.slice(0, 10) as item}
                {#if item !== currentQuestion}
                    <button on:click={() => searchNow(item)}
                        >{item}</button
                    >
                {/if}
            {/each}
        </div>
    </div>
{/if}

<style lang="scss">
    @import "./../../scss/components/_related-search";
</style>
