<!-- <svelte:options customElement="clever-search-answer-filter-indices" /> -->

<script lang="ts">
    import { _ } from "svelte-i18n";
    import { ConfigStore } from "../store/config";
    import {
        state_indicesList,
        state_searchResult,
        state_searchString,
        state_selectedIndicesList,
    } from "../store/state";

    import { slide } from "svelte/transition";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import type { IRequestSearch } from "../interfaces/request-search-question.interface";
    import { shadowDomUpdates } from "../helpers/shadowdom-updates";
    import { onMount } from "svelte";
    let showDrp: boolean = false;

    $: newSelectedIndices = !!$state_selectedIndicesList.length
        ? [...$state_selectedIndicesList]
        : [...$state_indicesList.map((x) => x.id)];

    state_selectedIndicesList.set(
        !!$state_selectedIndicesList.length
            ? [...$state_selectedIndicesList]
            : [...$state_indicesList.map((x) => x.id)]
    );

    // onMount(() => {
    //     shadowDomUpdates("clever-search-answer-filter-indices", true);
    // });

    const toggleDrp = () => {
        showDrp = !showDrp;
    };

    const handleOnKeyUp = (e) => {
        if (!showDrp) return;

        switch (e.key) {
            case "Escape":
                showDrp = false;
                break;

            default:
                break;
        }
    };

    const handleFilterNow = () => {
        state_selectedIndicesList.set(newSelectedIndices);

        if (!($state_searchString || $state_searchResult?.question)) {
            showDrp = false;
            return;
        }

        const data: IRequestSearch = {
            query: $state_searchString || $state_searchResult?.question,
            index: newSelectedIndices.toString().replace(", ", ""),
        };

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                detail: data,
            })
        );

        showDrp = false;
    };

    const chkChange = (e) => {
        if (e.target.checked) {
            newSelectedIndices = [...newSelectedIndices, e.target.dataset.id];
        } else {
            newSelectedIndices = newSelectedIndices.filter(
                (x) => x !== e.target.dataset.id
            );
        }
    };

    const getIndicesName = (id: string): string => {
        try {
            const x = $state_indicesList.find((x) => x.id == id);

            return x.data.name || id;
        } catch {
            console.error("Indices name missing");

            state_selectedIndicesList.set([]);
            return "";
        }
    };

    const selectAll = (e) => {
        if (e.target.checked) {
            newSelectedIndices = $state_indicesList.map((x) => x.id);
        } else {
            newSelectedIndices = [];
        }
    };
</script>

<svelte:window on:keyup={handleOnKeyUp} />

{#if $state_indicesList.length}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="cs-hyp" on:click={toggleDrp}>
        {#if $state_selectedIndicesList.length === $state_indicesList.length}
            {$_("answer_filter_indices.all_indices")}
            <i class={showDrp ? "cs-arrow cs-up" : "cs-arrow cs-down"} />
        {:else if $state_selectedIndicesList.length === 1}
            {getIndicesName($state_selectedIndicesList[0])}
            <i class={showDrp ? "cs-arrow cs-up" : "cs-arrow cs-down"} />
        {:else}
            ({$state_selectedIndicesList.length}) Indices
            <i class={showDrp ? "cs-arrow cs-up" : "cs-arrow cs-down"} />
        {/if}
    </div>
    {#if showDrp}
        <div class="cs-drp-wrapper">
            <ul class="cs-drp-list" in:slide|global out:slide|global>
                {#each $state_indicesList as item, i}
                    <li>
                        <label
                            title={item?.data?.description
                                ? item?.data?.description
                                : item.id}
                            for={i.toString()}
                        >
                            <input
                                disabled={newSelectedIndices.length === 1 &&
                                    newSelectedIndices.includes(item.id)}
                                data-id={item.id}
                                checked={newSelectedIndices.includes(item.id)}
                                on:change={chkChange}
                                type="checkbox"
                                class="cs-drp-item"
                                id={i.toString()}
                            />
                            <span />
                            {item?.data?.name ? item?.data?.name : item.id}
                        </label>
                    </li>
                {/each}
            </ul>
            <div class="cs-action-panel">
                {#if $ConfigStore.showSelectAllIndices}
                    <label title="Select All" for="lblSelectAll">
                        <input
                            data-id="lblSelectAll"
                            checked={newSelectedIndices.length ===
                                $state_indicesList.length}
                            on:change={selectAll}
                            type="checkbox"
                            id="lblSelectAll"
                        />
                        <span />
                        {$_("answer_filter_indices.select_all")}
                    </label>
                {/if}
                <button on:click={handleFilterNow} type="button"
                    >{$_("answer_filter_indices.filter_now")}</button
                >
            </div>
        </div>
        <div on:click={() => (showDrp = false)} class="cs-drp-overlay" />
    {/if}
{/if}

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if} -->

<style lang="scss">
    @import "./../../scss/components/_answer-filter-indices";
</style>
