import { get } from "svelte/store";
import type { IAnswer } from "../interfaces/answer.interface";
import { state_conversationResults } from "../store/state";
import { SessionStoreHandler } from "./session-storage-handler";

export const ConversationHistoryHelper = {
    addQuestion: (message: string, isGreeting: boolean, isStreaming: boolean): IAnswer => {
        const currentConversationResult: IAnswer[] = get(state_conversationResults);
        const questionOnlyItem: IAnswer = {
            question: message,
            thread_id: null,
            answer: "",
            answer_chunks: [],
            tool_calls: null,
            question_id: null,
            document_data: null,
            document_id: null,
            is_streaming: isStreaming,
            is_streamCancelled: false,
            is_greeting: isGreeting,
            has_unfinished_citation: false,
            position_in_thread: currentConversationResult.length
        };
        state_conversationResults.set([...currentConversationResult, questionOnlyItem]);
        return questionOnlyItem;
    },
    stopStreaming: (index: number): void => {
        const currentConversationResults = get(state_conversationResults);
        //const index: number = currentConversationResults.length - 1;
        if (currentConversationResults[index]?.is_streaming) {
            currentConversationResults[index].is_streaming = false;
            currentConversationResults[index].is_streamCancelled = true;
            state_conversationResults.set([...currentConversationResults]);
        }
    },
    isEmpty: (): boolean => {
        return get(state_conversationResults).length === 0;
    },
    getLastTurn: (): IAnswer => {
        return get(state_conversationResults)[get(state_conversationResults).length - 1];
    },
    updateLastTurn: (turn: IAnswer): void => {
        const conversationItem = [...get(state_conversationResults)];
        conversationItem[conversationItem.length - 1] = { ...turn };
        state_conversationResults.set([...conversationItem]);
        if (turn.question_id != null && !turn.is_greeting) {
            SessionStoreHandler.update({ lastQId: turn.question_id, threadId: turn.thread_id != null ? turn.thread_id : turn.question_id, lastQUrl: window?.location?.href});
        }
    },
    hideLastTurnOnError: (e: Error): void => {
        if (e?.name === 'AbortError') return;

        const conversationItem = [...get(state_conversationResults)];

        if (conversationItem.length === 0) return;

        conversationItem[conversationItem.length - 1] = { ...conversationItem[conversationItem.length - 1], hideOnChat: true };
        state_conversationResults.set([...conversationItem]);
    },
    getLastAnsweredId: (): string | null => {
        return SessionStoreHandler.get()?.lastQId;
    },
    getThreadId: (): string | null => {
        return SessionStoreHandler.get()?.threadId;
    },
    populateThread: (answers: IAnswer[]): void => {
        state_conversationResults.set([...answers]);
    },
    clear: (): void => {
        SessionStoreHandler.remove('threadId');
        SessionStoreHandler.remove('lastQId');
        SessionStoreHandler.remove('lastQUrl');
        state_conversationResults.set([]);
    },
    getUnanswered: (): { respondedToId: string | null, unrespondedCompilation: string | null } => {
        const lastIndex = get(state_conversationResults).map((item, index) => item?.question_id !== null ? index : -1).reduce((max, value) => Math.max(max, value), -1);
        if (lastIndex == -1) { // no previously answered question or greeting
            if (get(state_conversationResults).length > 0) {
                return { respondedToId: null, unrespondedCompilation: get(state_conversationResults).map((item) => item.question).join('\n') };
            } else {
                return { respondedToId: null, unrespondedCompilation: null };
            }
        } else { // previously answer they're responding to
            const questions: IAnswer[] = get(state_conversationResults).slice(lastIndex);
            if (questions.length == 1) {
                return { respondedToId: questions[0].question_id, unrespondedCompilation: null };
            } else if (questions.length > 1) {
                return { respondedToId: questions[0].question_id, unrespondedCompilation: questions.slice(1).map((item) => item.question).join('\n') };
            } else {
                return { respondedToId: null, unrespondedCompilation: null };
            }
        }
    }
}