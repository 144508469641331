import { get } from "svelte/store";
import type { IAnswer } from "../interfaces/answer.interface";
import { state_conversationResults } from "../store/state";
import { SessionStoreHandler } from "./session-storage-handler";
import { ConfigStore } from "../store/config";
import { enrichDocumentCitations, extractDocumentCitations, finishesDocumentCitations, hasUnfinishedDocumentCitations, identifyDocumentLinks, selectDocumentIdsInAnswer } from "./document-citations";

export const CitationHelper = {
    addReferences: (data: IAnswer): void => {
        switch (get(ConfigStore).filterCitations) {
            case 'no':
                // nothing to do
                break;
            case 'link':
                {
                    let extracted: { text: string, ids: string[] } = enrichDocumentCitations(data.answer, data.document_id, data.document_data);
                    //console.log("link:");
                    //console.log(extracted);
                    //console.log(data);
                    let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(extracted.text);
                    //console.log(scanned);
                    selectDocumentIdsInAnswer(data, extracted.ids); // remove unused documents from answer
                    data.answer = scanned.textWithoutCitation;
                    //console.log(data.answer);
                    data.has_unfinished_citation = scanned.result;
                }
                break;
            case 'remove':
                {
                    let extracted: { text: string, ids: string[] } = extractDocumentCitations(data.answer, data.document_id,data.document_data/*ConversationHistoryHelper.getLastTurn().document_data*/);
                    let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(extracted.text);
                    selectDocumentIdsInAnswer(data, extracted.ids); // remove unused documents from answer
                    data.answer = scanned.textWithoutCitation;
                    data.has_unfinished_citation = scanned.result;
                }
                break;
            case 'match-url':
                {
                    let extractedIds: string[] = identifyDocumentLinks(data.answer, data.document_id,data.document_data/*ConversationHistoryHelper.getLastTurn().document_data*/);
                    selectDocumentIdsInAnswer(data, extractedIds); // remove unused documents from answer
                    let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(data.answer);
                    data.answer = scanned.textWithoutCitation;
                    data.has_unfinished_citation = scanned.result;
                }
                break;    
        }    
    },
    addReferencesAndAnswer: (data: IAnswer): void => {
        
        const compiledAnswer = data.answer_chunks.map(chunk => chunk.content).join('');
        switch (get(ConfigStore).filterCitations) {
            case 'no':
                data.answer = compiledAnswer;
                break;
            case 'link':
                {
                    let extracted: { text: string, ids: string[] } = enrichDocumentCitations(compiledAnswer, data.document_id, data.document_data);
                    let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(extracted.text);
                    data.answer = scanned.textWithoutCitation;
                    data.has_unfinished_citation = scanned.result;
                }
                break;    
            case 'remove':
                {
                    let extracted: { text: string, ids: string[] } = extractDocumentCitations(compiledAnswer, data.document_id, data.document_data);
                    let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(extracted.text);
                    data.answer = scanned.textWithoutCitation;
                    data.has_unfinished_citation = scanned.result;
                }
            case 'match-url':    
                {
                    let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(compiledAnswer);
                    data.answer = scanned.textWithoutCitation;
                    data.has_unfinished_citation = scanned.result;
                }
                break;

        }
        //console.log("araa", data);
    },
    addReferencesChunked: (data: IAnswer, chunk: { content: string, ts: number }): void => {
        if (data.has_unfinished_citation) {
            if (finishesDocumentCitations(chunk.content)) {

                CitationHelper.addReferencesAndAnswer(data);
                
            } else {
                //console.log("Not finishing citation");
                // don't update the answer if the new chunk doesn't finish the citation
            }
        } else {
            switch (get(ConfigStore).filterCitations) {
                case 'no':
                    data.answer = data?.answer ? data?.answer + chunk.content : chunk.content;
                    break;
                case 'link':
                case 'remove':
                case 'match-url':    
                    {
                        let scanned: { textWithoutCitation: string, result: boolean } = hasUnfinishedDocumentCitations(chunk.content);
                        data.answer = data?.answer ? data?.answer + scanned.textWithoutCitation : scanned.textWithoutCitation;
                        data.has_unfinished_citation = scanned.result;
                    }
                    break;
            }
        }
        //console.log("araac", data);
    }
}