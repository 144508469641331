<!-- <svelte:options customElement="clever-search-alternative-answer" /> -->

<script lang="ts">
    import { _ } from "svelte-i18n";
    import { onMount } from "svelte";
    import { ApiTypes } from "../enums/api.enum";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import { shadowDomUpdates } from "../helpers/shadowdom-updates";
    import type { IAlternativeAnswer } from "../interfaces/alternative-answer.interface";
    import type { IRequestSearch } from "../interfaces/request-search-question.interface";
    import {
        state_alternativeSearchResults,
        state_busyApi,
        state_searchResult,
        state_searchString,
        state_showAlternative,
    } from "../store/state";
    import { ConfigStore } from "../store/config";

    import BusyComponent from "./busy.svelte";

    $: isAlternativeSearchBusy = $state_busyApi.includes(ApiTypes.postSearch);

    $: isPossibleAnswer = $ConfigStore.showDocDataOnNoAnswer;

    // onMount(() => {
    //     shadowDomUpdates("clever-search-alternative-answer", true);
    // });

    const useAlternativeForAnswer = (item: IAlternativeAnswer) => {
        const data: IRequestSearch = {
            query: $state_searchResult.question || $state_searchString,
            index: item.index,
            document_id: item.id,
            skipAlternative: true,
        };

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                detail: data,
            })
        );
    };

    const showAlternativeSection = (state: boolean) => {
        state_showAlternative.set(state);
    };
</script>

{#if !$state_showAlternative && $ConfigStore.toggleAlternative}
    <div class="cs-alternative-toggle">
        <div on:click={() => showAlternativeSection(true)}>
            {isPossibleAnswer
                ? "Show Possible Sources"
                : "Show Alternative Sources"}
            <i class="cs-arrow bottom" />
        </div>
    </div>
{:else}
    <div class="cs-alternative-wrapper">
        {#if isAlternativeSearchBusy}
            <BusyComponent />
        {:else if $state_alternativeSearchResults.length}
            <div class="cs-hint-title" part="cs-hint-title">
                {isPossibleAnswer
                    ? $_("alt_answer.possible_source")
                    : $_("alt_answer.alternative_source")}
                <button
                    class="cs-close-alternative"
                    on:click={() => showAlternativeSection(false)}
                    >{$_("alt_answer.close_alternative")}</button
                >
            </div>
            <div class="cs-alternative-result-wrapper">
                {#each $state_alternativeSearchResults as alternativeItem, i}
                    {#if alternativeItem.id !== $state_searchResult?.document_id[0]}
                        <div class="cs-search-result-support">
                            <div class="cs-search-result-answer-ref">
                                {#if alternativeItem.data.url}
                                    <div class="cs-search-result-main-title">
                                        <a
                                            href={alternativeItem.data.url}
                                            target={$ConfigStore.hyperlinkTargetType}
                                        >
                                            {#if $ConfigStore.showAnswerSourceLink}
                                                <div
                                                    class="cs-search-result-main-url"
                                                >
                                                    {alternativeItem.data.url}
                                                </div>
                                            {/if}
                                            <div class="cs-title">
                                                {alternativeItem.data.title}
                                            </div></a
                                        >
                                    </div>
                                {:else}
                                    <div class="cs-search-result-main-title">
                                        {alternativeItem.data.title}
                                    </div>
                                {/if}
                            </div>
                            {#if $ConfigStore.showAnswerDesc}
                                <div class="cs-search-result-support-content">
                                    {alternativeItem.data.content}
                                </div>
                            {/if}
                            <div class="cs-hyp-action-wrapper">
                                <span
                                    on:click={() =>
                                        useAlternativeForAnswer(
                                            alternativeItem
                                        )}
                                    class="cs-hyp-action"
                                    >{$_("alt_answer.use_this_source")}
                                    <i class="cs-arrow right" /></span
                                >
                            </div>
                        </div>
                    {/if}
                {/each}
            </div>
        {:else}
            <div class="cs-no-alternative">
                {$_("alt_answer.no_alternative_source")}
            </div>{/if}

        {#if $ConfigStore.customCSSUrl}
            <link
                href={$ConfigStore.customCSSUrl}
                rel="stylesheet"
                type="text/css"
            />
        {/if}
    </div>
{/if}

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if} -->

<style lang="scss">
    @import "./../../scss/components/alternative-answer";
</style>
