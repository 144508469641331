import type { IAnswer } from "../interfaces/answer.interface";
import type { IDocument } from "../interfaces/document.interface";

export function identifyDocumentLinks(text: string, documentIDs: string[], documents: IDocument[]): string[] {
    if (!documentIDs) {
        return [];
    }

    const presentIDs: { id: string, index: number }[] = [];

    for (let i = 0; i < documentIDs.length; i++) {
        if (documents[i].url?.length > 0) {
            const index = text.indexOf(documents[i].url);
            if (index !== -1) {
                presentIDs.push({ id: documentIDs[i], index });
            }
        }
    }
    if (presentIDs.length > 1) {
        presentIDs.sort((a, b) => a.index - b.index);
    }

    return presentIDs.map(p => p.id);
}

// remove document IDs from text and return them as a list
export function extractDocumentCitations(text: string, documentIDs: string[], documents: IDocument[]): { text: string, ids: string[] } {

    // exit if no documents are provided
    if (!documentIDs || documentIDs.length == 0) {
        return {
            text: text,
            ids: []
        };
    }

    // Regular expression to match IEEE-style document ID references
    //const pattern = /\s*\[([^\]]+)\]/g;
    const pattern =/\[((?:\d+[-,\s]*)+)\]/g;
    let match;
    let citationIds: number[] = [];
    let foundDocMap = {};
    
    while ((match = pattern.exec(text)) !== null) {
        citationIds = citationIds.concat(match[1].split(',').map(id => id.trim()).map(cite => { return parseInt(cite); }));
    }

    for (let i = 0; i < documentIDs.length; i++) {
        if (citationIds.indexOf(documents[i].citation_id) !== -1) {
            foundDocMap[documentIDs[i]] = documents[i].citation_id;
        }
    }

    // Remove the document ID references and preceding whitespace from the original text
    let cleanedText = text.replace(pattern, '');

    // Return the cleaned text and list of found IDs as a JSON object
    return {
        text: cleanedText,
        ids: Object.keys(foundDocMap)
    };
}

export function enrichDocumentCitations(text: string, documentIDs: string[], documents: IDocument[]): { text: string, ids: string[] } {
    
    // exit if no documents are provided
    if (!documentIDs || documentIDs.length == 0 || !text) {
        return {
            text: text,
            ids: []
        };
    }
    // console.log("enriched:");
    // console.log(documentIDs);
    // console.log(documents);

    //const pattern = /\s*\[([^\]a-zA-Z]+)\]/g;
    // regex pattern for IEEE-style document ID references, e.g. [1, 2, 3] and [3]
    //const pattern = /\[\s*\d+(\s*,\s*\d+)*\s*\]/g;
    const pattern =/\[((?:\d+[-,\s]*)+)\]/g;
    let match;
    let citationIds: number[] = [];
    let foundDocMap = {};

    while ((match = pattern.exec(text)) !== null) {
        citationIds = citationIds.concat(match[1].split(',').map(id => id.trim()).map(cite => { return parseInt(cite); }));
    }

    for (let i = 0; i < documentIDs.length; i++) {
        if (citationIds.indexOf(documents[i].citation_id) !== -1) {
            foundDocMap[documentIDs[i]] = documents[i].citation_id;
        }
    }
    //console.log(citationIds);
    //console.log(foundDocMap);

    // Function to replace each document ID reference with its corresponding {index}
    const replaceFunc = (match, p1) => {
        
        const replacedDocs: IDocument[] = p1.split(',')
            //.map(id => id.trim())
            //.map(cite => parseInt(cite))
            //.filter(idx => !isNaN(idx))
            .flatMap(reference => {
                if (reference.includes('-')) {
                  const [start, end] = reference.split('-').map(Number);
                  return Array.from({length: end - start + 1}, (_, i) => start + i);
                } else {
                  return Number(reference);
                }
              })
            .filter(value => Number.isInteger(value))
            .map(cite => findCitedDocumentWithLocalID(cite, documents))
            .filter((doc, index, self) => doc?.source_id && self.findIndex(d => d?.source_id === doc.source_id) === index); // remove duplicates
        replacedDocs.sort((a, b) => (a.local_id ? a.local_id : 0) - (b.local_id ? b.local_id : 0));
        const replaced = replacedDocs
            .map(doc => 
                {
                    if (doc.local_id) {
                        return '<a href="' + doc.url + '" class="citation-a" target="_blank"><sup class="citation-sup">' + doc.local_id + '</sup></a>';
                    } else {
                        return '';
                    }
                })
            .join('');  

        return replaced;
    };

    let cleanedText = text.replace(pattern, replaceFunc);

    return {
        text: cleanedText,
        ids: Object.keys(foundDocMap)
    };

}

function findDocumentWithLocalID(index: number, documents: IDocument[]): IDocument {
    if (!documents[index].local_id && documents[index].url?.length > 0) {
        // find the maximum local_id
        let maxLocalID = documents.map(doc => doc.local_id).reduce((a, b) =>{
            return (a ? a : 0) > (b ? b : 0) ? a : b;
        });
        documents[index].local_id = maxLocalID ? maxLocalID + 1 : 1;
        // give all docs with the same source_id the same local_id
        for (let doc of documents) {
            if (doc.source_id === documents[index].source_id) {
                doc.local_id = documents[index].local_id;
            }
        }
    }
    return documents[index];
}

function findCitedDocumentWithLocalID(cite: number, documents: IDocument[]): IDocument {
    let index = documents.findIndex(d => d.citation_id === cite);
    if (index !== -1) {
        if (!documents[index].local_id && documents[index].url?.length > 0) {
            // find the maximum local_id
            let maxLocalID = documents.map(doc => doc.local_id).reduce((a, b) =>{
                return (a ? a : 0) > (b ? b : 0) ? a : b;
            });
            documents[index].local_id = maxLocalID ? maxLocalID + 1 : 1;
            // give all docs with the same source_id the same local_id
            for (let doc of documents) {
                if (doc.source_id === documents[index].source_id) {
                    doc.local_id = documents[index].local_id;
                }
            }
        }
        return documents[index];
    } else {
        return null;
    }
}

export function hasUnfinishedDocumentCitations(text: string): { textWithoutCitation: string, result: boolean } {
    // Regular expression to find an opening bracket without a corresponding closing bracket
    let regex = /\[[^\]]*$/;
    let result = regex.test(text);
    let textWithoutCitation = text;

    // If an incomplete citation is found, remove it and everything after it
    if (result) {
        //console.log("Unfinished citation found");
        const matchIndex = text.search(regex);
        textWithoutCitation = text.substring(0, matchIndex);
    } else {
        // Regular expression to find a markdown link without a corresponding closing parenthesis
        regex = /\[.*\]\([^\)]*$/;
        result = regex.test(text);
        // If an incomplete markdown link is found, remove it and everything after it
        if (result) {
            //console.log("Unfinished markdown link found");
            const matchIndex = text.search(regex);
            textWithoutCitation = text.substring(0, matchIndex);
        }
    }

    return {
        textWithoutCitation,
        result
    };
}

export function finishesDocumentCitations(text: string): boolean {
    let regex = /[\]\)]/;
    if (regex.test(text)) {
        regex = /\]\([^\)]*$/;
        if (regex.test(text)) {
            return false;
        } else {
            return true;
        }
    } else {

        return false;
    }
}

export function selectDocumentIdsInAnswer(answer: IAnswer, documentIDs: string[]): void {
    // exit if no documents are provided
    if (!answer?.document_id || answer.document_id.length == 0) {
        return;
    }

    //console.log("Selecting document IDs in answer: " + documentIDs);

    const nIds: string[] = [];
    const nIndices: string[] = [];
    const nData: IDocument[] = [];

    for (let i = 0; i < documentIDs.length; i++) {
        const pos = answer.document_id.indexOf(documentIDs[i]);
        if (pos !== -1) {
            nIds.push(documentIDs[i]);
            if (answer.document_index) nIndices.push(answer.document_index[pos]);
            nData.push(answer.document_data[pos]);
        }
    }

    answer.document_id = nIds;
    if (answer.document_index) answer.document_index = nIndices;
    answer.document_data = nData;

    // remove documents from answer if not present in the provided array
    // for (let i = 0; i < answer.document_id.length; i++) {
    //     if (!documentIDs.includes(answer.document_id[i])) {
    //         //console.log("Removing document ID " + answer.document_id[i] + " from answer -> " + answer.document_data[i].url);
    //         answer.document_id.splice(i, 1);
    //         if (answer.document_index) answer.document_index.splice(i, 1);
    //         answer.document_data.splice(i, 1);
    //         i--;
    //     }
    // }
}