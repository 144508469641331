import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { addApiError, addBusyApi, removeBusyApi } from "../../helpers/handle-api";
import { ConfigStore } from "../../store/config";
import { state_apiErrors } from "../../store/state";


/** function to fetch GET /1/thread api end point */
export async function GetThread(threadId: string): Promise<any> {

    try {
        addBusyApi(ApiTypes.getThread);
        state_apiErrors.set(get(state_apiErrors).filter(x => x.apiType !== ApiTypes.getThread));

        let url = new URL(`${get(ConfigStore).apiBaseUrl}/1/question`);

        url.searchParams.set('threadId', threadId);

        if (!!get(ConfigStore)?.serverConfig?.id)
            url.searchParams.set('config', get(ConfigStore)?.serverConfig?.id);

        if (!!get(ConfigStore)?.serverConfig?.tenant_id)
            url.searchParams.set('tenant', get(ConfigStore)?.serverConfig?.tenant_id);


        return fetch(url.href, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                if (res?.ok)
                    return res;

                throw (res);
            })
            .catch((res) => {
                /** error api */
                addApiError({
                    apiType: ApiTypes.getThread,
                    status: res?.status,
                    statusText: res?.statusText,
                    error: res?.error,
                    errorType: res?.type
                });

                throw (res);
            })
            .finally(() => {
                removeBusyApi(ApiTypes.getThread);

            });

    } catch (e) {
        removeBusyApi(ApiTypes.getThread);
        throw (e);
    }
}
