import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { addApiError, addBusyApi, removeBusyApi } from "../../helpers/handle-api";
import type { IRequestSearch } from "../../interfaces/request-search-question.interface";
import { ConfigStore } from "../../store/config";
import { state_apiErrors } from "../../store/state";
import { Logger } from "../../telemetry/logger";

/** function to fetch POST /1/question api end point */
export async function postQuestion(params: IRequestSearch, signal: AbortSignal, wasRetried: boolean = false): Promise<any> {

    try {
        addBusyApi(ApiTypes.postQuestion);
        state_apiErrors.set(get(state_apiErrors).filter(x => x.apiType !== ApiTypes.postQuestion));

        let url;
        if (params.isGreetingRequest) {
            url = new URL(`${get(ConfigStore).apiBaseUrl}/1/greeting`);
            params.isGreetingRequest = undefined;
        } else {
            url = new URL(`${get(ConfigStore).apiBaseUrl}/1/question`);
        }

        if (!!get(ConfigStore)?.serverConfig?.tenant_id)
            url.searchParams.set('tenant', get(ConfigStore)?.serverConfig?.tenant_id);

        if (get(ConfigStore)?.serverConfig?.id)
            params.config = get(ConfigStore)?.serverConfig?.id;

        // include current local time with request (for use by prompt)
        if (!params.attributes) params.attributes = {};
        if (!params.attributes.date && (get(ConfigStore)?.injectDatetime == 'always' || (params.response_to_question_id && get(ConfigStore)?.injectDatetime == 'interaction') || (!params.response_to_question_id && get(ConfigStore)?.injectDatetime == 'greeting'))) params.attributes.date = Date();
        if (!params.url) params.url = window?.location?.href;

        return fetch(url.href, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            signal,
            body: JSON.stringify(params)
        })
            .then((res) => {

                if (res?.ok)
                    return res;

                if (res?.status === 404 && !wasRetried) {
                    return new Promise(resolve => 
                    setTimeout(async () => {
                        var logger = new Logger();
                        logger.warn('Retrying POST /1/question after 404');
                        const value = await postQuestion(params, signal, true);
                        resolve(value);
                      }, 500
                    ));
                }

                throw (res);
            })
            .catch((res) => {
                /** error api */
                addApiError({
                    apiType: ApiTypes.postQuestion,
                    status: res?.status,
                    statusText: res?.statusText,
                    error: res?.error,
                    errorType: res?.type || res?.name
                });

                throw (res);
            })
            .finally(() => {
                removeBusyApi(ApiTypes.postQuestion);
            });

    } catch (e) {
        removeBusyApi(ApiTypes.postQuestion);
        throw (e);
    }
}
