/** enum contains all window action names the widget supports */
export enum WindowsCustomEventTypes {

    /** event to notify client of the widget is ready */
    ready = 'cs_ready',


    /** widget successfully downloaded config from server */
    configReady = 'cs_configReady',

    /** widget error downloaded config from server */
    configError = 'cs_configError',


    /** widget checked greeting message */
    greetingInitCompleted = 'cs_greetingInitCompleted',


    /** 
 * event to execute on widget init 
 * @param [required]  {detail: string}
 */
    requestServerInit = 'cs_requestServerInit',


    /** 
     * event to execute search
     * @param [required] {details:IRequestSearch}
     */
    requestSearch = 'cs_requestSearch',


    /** 
    * event to request suggestions for search text
    * 
    * @param  [required] {detail: IRequestSearchSuggestion}
    */
    requestSuggestion = 'cs_requestSuggestions',

    /** 
    * event to request FAQ
    * 
    * @param  [required] {detail: IRequestSearchFaq}
    */
    requestFAQ = 'cs_requestFAQ',


    /** 
     * Custom event to request indices
     */
    requestIndices = 'cs_requestIndices',

    /** 
     * Event for loading current thread history
     */
    requestThread = 'cs_requestThread',

    /** 
     * Event for clearing current thread history and starting over
     */
    restartThread = 'cs_restartThread',

    /** 
     * Event for triggering an initial greeting
     */
    requestGreeting = 'cs_requestGreeting',


    /** event to toggle sidebar component 
     * @param [optional] {detail:boolean}
     * 
     * true = show 
     * 
     * false = hide
    */
    toggleView = 'cs_toggleView',

    /** event to show hide toggle component 
    * @param [optional] {detail:boolean}
    * 
    * true = show 
    * 
    * false = hide
    */
    showBadge = 'cs_badgeView',


    /**
     * custom event to update rating
     * 
     * @param [required] {detail: IUpdateRating}
     */
    updateRating = 'cs_updateRating',


    /** 
     * Custom even to notify the rating submission status
     * 
     * @param  [required] {detail: boolean}
     */
    UpdateRatingStatus = 'cs_updateRatingStatus',


    /** 
     * Custom even to update widget configuration
     * 
     * @param  [required] {detail: IConfigStore}
     */
    updateConfig = 'cs_updateConfig',


    /** 
     * Custom even to request search from speech recognition component
     * 
     * @param  [required] {detail: string}
     */
    speechSearchRequest = 'cs_speechSearchRequest',


    /** custom event to fetch server config
     * 
     * * @param  [required] {detail: string}
     */

    requestServerConfig = 'cs_requestServerConfig',


    /** custom even emitted when required to fetch POST option */
    requestPostOption = 'cs_requestPostOptions',


    /** custom even to show the greeting popup */
    showGreetingPopup = 'cs_showGreetingPopup',

}