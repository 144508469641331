import { SeverityLevel, type ApplicationInsights } from "@microsoft/applicationinsights-web";
import { LogLevel } from "../enums/log-level.enum";
import { variables } from './../../variables';

export let Logger_ai: ApplicationInsights;
export class Logger {

    private minLevelForConsole: LogLevel;
    private minLevelForUpload: LogLevel;

    constructor() {
        if (variables?.DEBUG_MODE && variables?.DEBUG_MODE != "false") {
            console.log("Logger is in debug mode");
            this.minLevelForConsole = LogLevel.Verbose;
            this.minLevelForUpload = LogLevel.None;
        } else {
            this.minLevelForConsole = LogLevel.Warn;
            this.minLevelForUpload = LogLevel.Info;
        }
    }

    setAppInsights(ai: ApplicationInsights): void {
        Logger_ai = ai;
    }

    debug(msg: string, details?: any) {
        this.writeToLog(msg, LogLevel.Debug, details);
    }

    info(msg: string, details?: any) {
        this.writeToLog(msg, LogLevel.Info, details);
    }

    warn(msg: string, details?: any) {
        this.writeToLog(msg, LogLevel.Warn, details);
    }

    error(msg: string, details?: any) {
        this.writeToLog(msg, LogLevel.Error, details);
    }

    log(msg: string, details?: any) {
        this.writeToLog(msg, LogLevel.Verbose, details);
    }

    private writeToLog(msg: string, level: LogLevel, details?: any) {

        if (this.minLevelForConsole !== LogLevel.None && level >= this.minLevelForConsole) {

            let value: string = LogLevel[level] + ": " + msg;

            console.log(value + " - ", details);

        }

        if (Logger_ai && this.minLevelForUpload !== LogLevel.None && level >= this.minLevelForUpload) {
            try {

                if (details) {
                    Logger_ai.trackTrace({
                        message: msg + " | " + JSON.stringify(details),
                        severityLevel: (level == LogLevel.Error) ? SeverityLevel.Error : (level == LogLevel.Warn) ? SeverityLevel.Warning : (level == LogLevel.Info) ? SeverityLevel.Information : SeverityLevel.Verbose
                    });
                } else {
                    Logger_ai.trackTrace({
                        message: msg,
                        severityLevel: (level == LogLevel.Error) ? SeverityLevel.Error : (level == LogLevel.Warn) ? SeverityLevel.Warning : (level == LogLevel.Info) ? SeverityLevel.Information : SeverityLevel.Verbose
                    });
                }
            } catch (e) {
                console.dir(e);
            }
        }
    }
}

export const defaultLogger: Logger = new Logger();
