<!-- <svelte:options customElement="clever-search-suggestion" /> -->

<script lang="ts">
    /** store*/
    import {
        state_searchString,
        state_searchSuggestion,
        state_selectedIndicesList,
    } from "../store/state";
    import { ConfigStore } from "./../store/config";

    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import type { IRequestSearch } from "../interfaces/request-search-question.interface";
    import type { ISearchSuggestion } from "../interfaces/search-suggestion.interface";
    import { shadowDomUpdates } from "../helpers/shadowdom-updates";
    import { onMount } from "svelte";

    export let isautosuggestionup;

    $: getSuggestionRegEx = new RegExp($state_searchString, "gi");

    let focusedIndex: number = 0;

    // onMount(() => {
    //     shadowDomUpdates("clever-search-suggestion", true);
    // });

    const searchSuggestionItem = (x: ISearchSuggestion): void => {
        const data: IRequestSearch = {
            query: x.data.question,
        };

        if (x.data.index) {
            data.index = x.data.index;
        } else if ($state_selectedIndicesList.length) {
            data.index = $state_selectedIndicesList
                .toString()
                .replace(", ", "");
        }

        if (x.data.document_id) data.document_id = x.data.document_id;

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                detail: data,
            })
        );
    };

    const handleOnKeyUp = (e) => {
        if (!$state_searchSuggestion?.length) return;

        switch (e.key) {
            // case "Enter":
            //     searchSuggestionItem($state_searchSuggestion[focusedIndex]);
            //     break;
            case "ArrowDown":
                moveNextItem(1);
                break;
            case "ArrowUp":
                moveNextItem(-1);
                break;
            default:
                break;
        }
    };

    const moveNextItem = (direction: number): void => {
        if (direction === 1) {
            if ($state_searchSuggestion[focusedIndex + 1]) focusedIndex += 1;
        } else {
            if ($state_searchSuggestion[+focusedIndex - 1])
                focusedIndex = +focusedIndex - 1;
        }
    };
</script>

<div
    class="cs-hint-inner-wrapper"
    class:cs-hint-up-direction={isautosuggestionup}
>
    <ul>
        {#each $state_searchSuggestion as suggestionItem, i}
            <li
                class:active={focusedIndex == i}
                id={`sc-${i}`}
                on:click={() => searchSuggestionItem(suggestionItem)}
            >
                {@html suggestionItem.data.question.replace(
                    getSuggestionRegEx,
                    `<b>${$state_searchString}</b>`
                )}
            </li>
        {/each}
    </ul>
</div>
<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if} -->
<svelte:window on:keyup={handleOnKeyUp} />

<style lang="scss">
    @import "./../../scss/components/_search-input-suggestion";
</style>
