import { WidgetType } from "../../enums/widget-type.enum";
import { ConfigStore } from "../../store/config";
import { Logger } from "../../telemetry/logger";
import { get } from "svelte/store";
import { GetThread } from "../api/get-thread";
import { ConversationHistoryHelper } from "../../helpers/conversation-history-helper";
import type { IAnswer } from "../../interfaces/answer.interface";
import { state_conversationResults, state_updatedAttributes } from "../../store/state";
import { CitationHelper } from "../../helpers/citation-helper";
import { MarkupHelper } from "../../helpers/markup-helper";
import { WindowsCustomEventTypes } from "../../enums/windows-custom-event.enum";

export class ThreadHandler {

    private logger = new Logger();
    private md;

    constructor() { 
        this.md = new MarkupHelper();
    }

    public async get() {

        if ([WidgetType.fb].includes(get(ConfigStore).widgetType) 
            || !ConversationHistoryHelper.getThreadId())
            return;

        try {

            const res = await GetThread(ConversationHistoryHelper.getThreadId());

            if (res?.ok) {
                const thread = await res.json();
                //console.dir(thread);
                const answers: IAnswer[] = [];
                for (let i = 0; i < thread.hits.length; i++) {
                    if (thread.hits[i].data.answer?.content || ( (i+1 < thread.hits.length) && !thread.hits[i+1].data.question && thread.hits[i+1].data.answer?.content )) {
                        const answer: IAnswer = {
                            question: thread.hits[i].data.question,
                            thread_id: thread.hits[i].data.conversation?.thread_id,
                            answer: this.md.render(thread.hits[i].data.answer.content),
                            answer_chunks: [],
                            tool_calls: null,
                            question_id: thread.hits[i].id,
                            document_data: thread.hits[i].data.answer.document_data,
                            document_id: thread.hits[i].data.answer.document_id,
                            is_greeting: !thread.hits[i].data.conversation?.thread_id || (thread.hits[i].data.conversation?.thread_id == thread.hits[i].id),
                            has_unfinished_citation: false,
                            position_in_thread: null // not required
                        };
                        CitationHelper.addReferences(answer);
                        answers.push(answer);
                    }

                }
                /*const answers: IAnswer[] = thread.hits.map(h => {
                    const answer: IAnswer = {
                        question: h.data.question,
                        thread_id: h.data.conversation?.thread_id,
                        answer: h.data.answer.content ? this.md.render(h.data.answer.content) : h.data.answer.content,
                        answer_chunks: [],
                        tool_calls: null,
                        question_id: h.id,
                        document_data: h.data.answer.document_data,
                        document_id: h.data.answer.document_id,
                        is_greeting: !h.data.conversation?.thread_id || (h.data.conversation?.thread_id == h.id),
                        has_unfinished_citation: false,
                        position_in_thread: null // not required
                    };
                    CitationHelper.addReferences(answer);
                    return answer;
                });*/
                answers.reverse();
                
                ConversationHistoryHelper.populateThread(answers);

                window.dispatchEvent(new CustomEvent(WindowsCustomEventTypes.greetingInitCompleted));
                //console.dir(answers);
            }

        } catch (e) {
            this.logger.error('ThreadHandler/get', e)
        }

    }

    public clear() {
        state_updatedAttributes.set(null);
        ConversationHistoryHelper.clear();
    }

}