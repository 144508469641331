import * as StateItems from './../store/state';

/** method to update the config store   */
export function updateStateStore(x: any): void {

    const newConfig: any = {};
    const currentStateItems = Object.getOwnPropertyNames(StateItems).map(x => x.substr(6)) 

    for (let key in x) {

        if (currentStateItems.includes(key)) {
            newConfig[key] = x[key];
        }
    }

    if (!!Object.keys(newConfig).length)
        Object.keys(newConfig).forEach(element => {

            (StateItems['state_' + element]).set(newConfig[element]);
        });

}

