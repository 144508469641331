import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { addApiError, addBusyApi, removeBusyApi } from "../../helpers/handle-api";
import type { IAnswer } from "../../interfaces/answer.interface";
import { ConfigStore } from "../../store/config";
import { state_apiErrors } from "../../store/state";
import { Logger } from "../../telemetry/logger";


/** function to fetch GET /1/question/${answer.question_id}/completion` api end point */
export async function GetQuestionRelatedSuggestions(answer: IAnswer, signal: AbortSignal, wasRetried: boolean): Promise<any> {

    return new Promise(resolve => 
        setTimeout(async () => {
            
            let responseValue;

            try {

                addBusyApi(ApiTypes.getQuestionRelatedSuggestions);
                state_apiErrors.set(get(state_apiErrors).filter(x => x.apiType !== ApiTypes.getQuestionRelatedSuggestions));
        
                let url = new URL(`${get(ConfigStore).apiBaseUrl}/1/question/${answer.question_id}/completion`);
        
                url.searchParams.set('filter', 'suggested_search');
                if (!!get(ConfigStore)?.serverConfig?.tenant_id) url.searchParams.set('tenant', get(ConfigStore)?.serverConfig?.tenant_id);
                if (!!get(ConfigStore)?.serverConfig?.id) url.searchParams.set('config', get(ConfigStore)?.serverConfig?.id);
        
                responseValue = fetch(url.href, {
                    method: 'GET',
                    signal,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(async (res) => {
                        if (res.ok)
                            return res;
        
                        if (res?.status === 404 && !wasRetried) {
                            var logger = new Logger();
                            logger.warn(`Retrying POST /1/question/${answer.question_id}/completion after 404`);
                            return await GetQuestionRelatedSuggestions(answer, signal, true);
                            /*return new Promise(resolve => 
                            setTimeout(async () => {
                                var logger = new Logger();
                                logger.warn(`Retrying POST /1/question/${answer.question_id}/completion after 404`);
                                const value = await GetQuestionRelatedSuggestions(answer, signal, true);
                                resolve(value);
                              }, 500
                            ));*/
                        }
        
                        throw (res);
                    })
                    .catch((res) => {
                        /** error api */
                        addApiError({
                            apiType: ApiTypes.getQuestionRelatedSuggestions,
                            status: res?.status,
                            statusText: res?.statusText,
                            errorType: res?.type
                        });
        
                        throw (res);
                    })
                    .finally(() => {
                        removeBusyApi(ApiTypes.getQuestionRelatedSuggestions);
        
                    });
        
            } catch (e) {
                removeBusyApi(ApiTypes.getQuestionRelatedSuggestions);
                throw (e);
            }
            resolve(responseValue);
          }, 750
        ));

    
}
