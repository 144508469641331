
import type { ISourceRelatedSearchSuggestions } from "../../interfaces/source-related-search-suggestions.interface";
import { get } from "svelte/store";
import { ApiTypes } from "../../enums/api.enum";
import { WindowsCustomEventTypes } from "../../enums/windows-custom-event.enum";
import type { IAnswer } from "../../interfaces/answer.interface";
import { state_busyApi, state_conversationResults, state_sourceRelatedSearchSuggestions } from "../../store/state";
import { Logger } from "../../telemetry/logger";
import { GetSourceRelatedSuggestions } from "../api/get-source-related-suggestions";
import { ConfigStore } from "../../store/config";
import { ConversationHistoryHelper } from "../../helpers/conversation-history-helper";


export class SourceRelatedSuggestionsHandler {


    private logger = new Logger();;

    private abortController;

    constructor() {

        this.abort();
    }

    public async get(answer: IAnswer) {

        if (!get(ConfigStore)?.serverConfig?.document_prompts?.includes('suggested_search')
            || (answer?.document_id.length && !answer?.document_id[0])
            || (answer?.document_index.length && !answer?.document_index[0])
            || (answer?.document_id.length && answer?.document_id[0] == get(state_sourceRelatedSearchSuggestions)?.document_id)
            /** avoid requesting when last message is greeting message */
            || ConversationHistoryHelper.getLastTurn()?.is_greeting)
            return;

        this.clear();

        try {

            this.abortController?.abort();
            this.abortController = new AbortController();
            const res = await GetSourceRelatedSuggestions(answer, this.abortController.signal);

            if (res.ok) {

                const data: any = await res.json();
                const sourceRelatedSearchSuggestions: ISourceRelatedSearchSuggestions = {
                    data: data.suggested_search.answer,
                    document_id: answer?.document_id[0],
                    index: answer?.document_index[0]
                }
                state_sourceRelatedSearchSuggestions.set(sourceRelatedSearchSuggestions);

            }
        } catch (e) {
            this.logger.error('SourceRelatedSuggestionsHandler/get', e)
        }
    }

    private clear(): void {
        state_sourceRelatedSearchSuggestions.set(null);
    }

    /** method to abort pending operation */
    private abort(): void {

        /** check for type of action */
        const abortNow = (e): void => {
            if (get(state_busyApi).includes(ApiTypes.getSourceRelatedSuggestions))
                this.abortController?.abort();
        }

        [WindowsCustomEventTypes.requestPostOption].forEach(evt =>
            window.addEventListener(evt, abortNow)
        );
    }

}