import { get } from "svelte/store";
import { ConfigStore } from "../store/config";

// Function to search for a tag within all shadow DOMs on the page
export function shadowDomUpdates(tagName: string, appendInlineCSS: boolean) {

    if ((!get(ConfigStore).customInlineCSS?.length || !appendInlineCSS)) return;

    // Check if the browser supports shadow DOM
    if (!document.documentElement.attachShadow) {
        console.error('Shadow DOM is not supported in this browser.');
        return null;
    }

    // Get all elements on the page
    const allElements = document.getElementsByTagName('*');

    // Array to store the elements with the desired tag
    const elements = [];

    const baseTag = document.querySelector(tagName)

    if (baseTag && appendInlineCSS) {
        addCustomCssToShadowRoot(baseTag);
        return;
    }


    // Loop through all elements and check for shadow DOMs
    for (let i = 0; i < allElements.length; i++) {
        const element = allElements[i];
        const shadowRoot = element.shadowRoot;

        if (shadowRoot) {
            // Use the querySelector method to search for the tag within the shadow DOM
            const foundElement = shadowRoot.querySelector(tagName);

            if (foundElement) {
                elements.push(foundElement);
            }

            // Check if the shadow DOM has any nested shadow DOMs
            const nestedElements = shadowRoot.querySelectorAll('*');
            for (let j = 0; j < nestedElements.length; j++) {
                const nestedElement = nestedElements[j];
                const nestedShadowRoot = nestedElement.shadowRoot;

                if (nestedShadowRoot) {
                    const foundNestedElement = nestedShadowRoot.querySelector(tagName);
                    if (foundNestedElement) {
                        elements.push(foundNestedElement);
                    }
                }
            }
        }
    }

    if (elements.length && appendInlineCSS)
        addCustomCssToShadowRoot(elements[0]);

}

function addCustomCssToShadowRoot(el) {
    var sheet = new CSSStyleSheet
    sheet.replaceSync(get(ConfigStore).customInlineCSS)
    el.shadowRoot.adoptedStyleSheets = [sheet]
}
