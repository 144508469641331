<!-- <svelte:options customElement="clever-search-answer-source" /> -->

<script lang="ts">
    import { slide } from "svelte/transition";
    import { ConfigStore } from "../store/config";
    import { state_searchResult } from "../store/state";

    let MultiSourceData: {
        sourceId: string;
        content: string[];
        title: string;
        url: string;
        localId: number;
    }[] = [];

    let multiVisibleSourceContent: string[] = [];

    $: isMultipleSources();

    // onMount(() => {
    //     shadowDomUpdates("clever-search-answer-source", true);
    // });

    const isMultipleSources = () => {
        try {
            const isMultipleSources =
                $state_searchResult?.document_data.length > 1;

            if (isMultipleSources) {
                buildMultiSourceData();
            } else {
                MultiSourceData = [];
            }
        } catch {
            MultiSourceData = [];
        }
    };

    const buildMultiSourceData = () => {
        MultiSourceData = [];
        for (let item of $state_searchResult.document_data) {
            const hasSourceIndex = MultiSourceData.findIndex(
                (x) => x.sourceId === item?.source_id
            );

            if (hasSourceIndex > -1) {
                MultiSourceData[hasSourceIndex].content.push(item.content);
            } else {
                MultiSourceData.push({
                    sourceId: item.source_id,
                    content: [item.content],
                    title: item.title,
                    url: item.url,
                    localId: item.local_id,
                    
                });
            }
        }
        MultiSourceData.sort((a, b) => a.localId - b.localId);
    };

    const urlify = (text: string): string => {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '">' + url + "</a>";
        });
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    };
</script>


<div class="cs-search-result-title">
    {$_("answer_source.answer_is_generate_from_below_sources")}
</div>
{#if MultiSourceData.length < 1}
    <div class="cs-search-result-support">
        <div class="cs-search-result-answer-ref">
            <div class="cs-search-result-main-title">
                {#if $state_searchResult.document_data[0].url}
                    <a
                        href={$state_searchResult.document_data[0].url}
                        target={$ConfigStore.hyperlinkTargetType}
                    >
                        {#if $ConfigStore.showAnswerSourceLink}
                            <div class="cs-search-result-main-url">
                                {$state_searchResult.document_data[0].url}
                            </div>
                        {/if}
                        {#if $state_searchResult.document_data[0].title}
                            <div class="cs-title">
                                {$state_searchResult.document_data[0].title}
                            </div>
                        {/if}
                    </a>
                {:else if $state_searchResult.document_data[0].title}
                    <div>
                        {$state_searchResult.document_data[0].title}
                    </div>
                {/if}
            </div>
        </div>
        {#if $ConfigStore.showAnswerDesc}
            <div
                class:active={multiVisibleSourceContent.includes(
                    "single-1"
                )}
                transition:slide|global
                class="cs-search-result-support-content"
            >
                <div class="cs-search-result-support-content-item">
                    {@html $state_searchResult.document_data[0].content
                    }
                </div>
            </div>

            <div>
                {#if multiVisibleSourceContent.includes("single-1")}
                    <span
                        class="cs-source-content-toggle"
                        on:click={() => (multiVisibleSourceContent = [])}
                    >
                        {$_("answer_source.show_less")}
                        <i class="cs-arrow right" /></span
                    >
                {:else}
                    <span
                        class="cs-source-content-toggle"
                        on:click={() =>
                            (multiVisibleSourceContent = ["single-1"])}
                        >{$_("answer_source.full_content")}
                        <i class="cs-arrow right" /></span
                    >
                {/if}
            </div>
        {/if}
    </div>
{:else}
    {#each MultiSourceData as sourceItem}
        <div class="cs-search-result-multi-wrapper">
            <div class="cs-search-result-answer-ref">
                <div class="cs-search-result-main-title">
                    {#if sourceItem.url}
                        <a
                            href={sourceItem.url}
                            target={$ConfigStore.hyperlinkTargetType}
                        >
                            {#if $ConfigStore.showAnswerSourceLink}
                                <div class="cs-search-result-main-url">
                                    <a
                                        href={sourceItem.url}
                                        target={$ConfigStore.hyperlinkTargetType}
                                        >{sourceItem.url}</a
                                    >
                                </div>
                            {/if}
                            {#if sourceItem.title}
                                <div class="cs-title">
                                    {sourceItem.title}
                                </div>
                            {/if}
                        </a>
                    {:else if sourceItem.title}
                        <div>
                            {sourceItem.title}
                        </div>
                    {/if}
                </div>
            </div>
            {#if $ConfigStore.showAnswerDesc}
                <div
                    class:active={multiVisibleSourceContent.includes(
                        sourceItem.sourceId
                    )}
                    transition:slide|global
                    class="cs-search-result-support-content"
                    data-id={sourceItem.sourceId}
                >
                    {#each sourceItem.content as sourceItemContent}
                        <div class="cs-search-result-support-content-item">
                            {@html sourceItemContent}
                        </div>
                    {/each}
                </div>

                <div>
                    {#if multiVisibleSourceContent.includes(sourceItem.sourceId)}
                        <span
                            class="cs-source-content-toggle"
                            on:click={() =>
                                (multiVisibleSourceContent =
                                    multiVisibleSourceContent.filter(
                                        (x) => x != sourceItem.sourceId
                                    ))}
                        >
                            {$_("answer_source.show_less")}
                            <i class="cs-arrow right" /></span
                        >
                    {:else}
                        <span
                            class="cs-source-content-toggle"
                            on:click={() =>
                                (multiVisibleSourceContent = [
                                    ...multiVisibleSourceContent,
                                    sourceItem.sourceId,
                                ])}
                        >
                            {$_("answer_source.full_content")}
                            <i class="cs-arrow right" /></span
                        >
                    {/if}
                </div>
            {/if}
        </div>
    {/each}
{/if}

<!-- <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" /> -->

<style lang="scss">
    @import "./../../scss/components/_answer-source";
</style>
