<!-- <svelte:options customElement="clever-search-faq" /> -->

<script lang="ts">
    import {
        state_busyApi,
        state_faq,
        state_selectedIndicesList,
    } from "../store/state";
    import { ConfigStore } from "./../store/config";

    import { ApiTypes } from "../enums/api.enum";
    import { WindowsCustomEventTypes } from "../enums/windows-custom-event.enum";
    import type { ISearchFAQ } from "../interfaces/faq.interface";
    import type { IRequestSearchFaq } from "../interfaces/request-search-faq.interface";
    import type { IRequestSearch } from "../interfaces/request-search-question.interface";
    import { _ } from "svelte-i18n";

    $: isBusy = $state_busyApi.includes(ApiTypes.getSuggestions);

    let faqConfig: string = "__NOFAQ";

    // onMount(() => {
    //     shadowDomUpdates("clever-search-faq", true);
    // });

    ConfigStore.subscribe((config) => {
        if (config.serverConfig?.id != faqConfig && !isBusy) {
            faqConfig = config.serverConfig?.id;

            if (config.showFAQ == false) return;
            
            const data: IRequestSearchFaq = {
                maxHits: 5,
                config: config.serverConfig?.id,
            };

            window.dispatchEvent(
                new CustomEvent(WindowsCustomEventTypes.requestFAQ, {
                    detail: data,
                })
            );
        }
    });

    const searchFaqItem = (x: ISearchFAQ): void => {
        const data: IRequestSearch = {
            query: x.data.question,
        };

        if (x.data.index) {
            data.index = x.data.index;
        } else if ($state_selectedIndicesList.length) {
            data.index = $state_selectedIndicesList
                .toString()
                .replace(", ", "");
        }

        if (x.data.document_id) data.document_id = x.data.document_id;

        window.dispatchEvent(
            new CustomEvent(WindowsCustomEventTypes.requestSearch, {
                detail: data,
            })
        );
    };
</script>

{#if $state_faq.length}
    <div class={"faq-content-wrapper cs-type-" + $ConfigStore.widgetType}>
        <div class="cs-faq-title">{$_("faq.title_faq")}</div>
        <div class="cs-faq-btn-wrapper">
            {#each $state_faq as item, i}
                <button on:click={() => searchFaqItem(item)}>
                    {item.data.question}
                </button>
            {/each}
        </div>
    </div>
{/if}

<!-- {#if $ConfigStore.customCSSUrl}
    <link href={$ConfigStore.customCSSUrl} rel="stylesheet" type="text/css" />
{/if} -->

<style lang="scss">
    @import "./../../scss/components/_faq";
</style>
